import ErrorPage from '@/components/ErrorPage';
import { useAuth } from '@/contexts/auth.context';
import LoadingPage from '@/pages/LoadingPage';
import LoginPage from '@/pages/LoginPage';
import { ServerOff, Unplug } from 'lucide-react';
import { Outlet } from 'react-router-dom';

export default function AuthenticatedRoute() {
  const { isAuthenticated, isLoading, isNetworkError, isServerDown } =
    useAuth();

  if (isNetworkError) {
    return (
      <ErrorPage
        enableSupportButton
        title="Parece que você perdeu a conexão"
        subtitle="Reestabeleça a sua conexão com a internet"
        icon={Unplug}
      />
    );
  }
  if (isServerDown) {
    return (
      <ErrorPage
        enableSupportButton
        icon={ServerOff}
        title="Problemas técnicos com o servidor"
        description="Tente novamente mais tarde"
      />
    );
  }
  if (isLoading) return <LoadingPage />;
  if (!isAuthenticated) return <LoginPage />;
  return <Outlet />;
}
