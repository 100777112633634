import Frame from '@/components/Frame';
import DefaultHighlighter from '@/components/Highlighter';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Separator } from '@/components/ui/separator';
import { cn } from '@/lib/utils';
import { TEvent } from '@/types';
import dayjs from 'dayjs';
import {
  Calendar,
  Caravan,
  Clock,
  LucideIcon,
  Mail,
  MapPinIcon,
  PartyPopper,
  Phone,
  Truck,
  User,
} from 'lucide-react';
import { ComponentProps, ReactNode } from 'react';
import { eventStages } from './events.config';

const defaultIconSize = 28;

type EventInfoProps = ComponentProps<'div'> & {
  children: ReactNode;
  icon?: LucideIcon;
  iconColor?: string;
  className?: string;
  reverse?: boolean;
  iconSize?: number;
};

function EventInfo({
  children,
  icon,
  iconSize,
  iconColor,
  className,
  ...props
}: EventInfoProps) {
  const InfoIcon = icon;

  return (
    <div {...props} className={cn('flex w-fit items-start gap-2', className)}>
      {icon && InfoIcon ? (
        <InfoIcon
          size={iconSize ?? defaultIconSize}
          className={cn(iconColor ? `text-${iconColor}` : '')}
        />
      ) : (
        ''
      )}

      {children}
    </div>
  );
}

function DefaultPopoverContent({ children }: { children: string | ReactNode }) {
  return (
    <PopoverContent className="mt-0 w-full max-w-72 rounded-md bg-dark-gray/90 px-4 py-2 text-white md:w-fit md:max-w-none">
      {children}
    </PopoverContent>
  );
}

type EventContainerProps = {
  event: TEvent;
  search?: string;
};

export default function EventCard({ event, search }: EventContainerProps) {
  const start_date = event.data_hora_inicio
    ? dayjs(event.data_hora_inicio).format('DD/MM/YYYY')
    : null;

  const end_date = event.data_hora_fim
    ? dayjs(event.data_hora_fim).format('D/MM/YYYY')
    : null;

  const start_time = event.data_hora_inicio
    ? dayjs(event.data_hora_inicio).format('H:mm [h]')
    : null;

  const end_time = event.data_hora_fim
    ? dayjs(event.data_hora_fim).format('H:mm [h]')
    : null;

  return (
    <Frame
      key={event.id_evento}
      className={
        'small-text bg-white/80 text-foreground transition-all duration-500 dark:bg-dark-gray/20'
      }
      moreContentText={{
        closed: 'Ver mais detalhes',
        opened: 'Ver menos detalhes',
      }}
      moreContent={
        <div className="mt-5 flex w-full flex-wrap gap-x-10 gap-y-5">
          <Popover>
            <PopoverTrigger
              className="w-fit"
              title={`Esse evento ${
                !event.pormade_movel ? 'NÃO' : ''
              } contará com o PORMADE MOVEL`}
            >
              <EventInfo icon={Truck}>
                <p className="text-start">
                  <b>PORMADE MÓVEL:</b> {event.pormade_movel ? 'Sim' : 'Não'}
                </p>
              </EventInfo>
            </PopoverTrigger>
            <DefaultPopoverContent>
              Esse evento <b>{!event.pormade_movel ? 'NÃO' : ''}</b> contará com
              o<b> PORMADE MÓVEL</b>
            </DefaultPopoverContent>
          </Popover>

          <Popover>
            <PopoverTrigger
              className="w-fit"
              title={`Esse evento ${
                !event.showroom_movel ? 'NÃO' : ''
              } contará com o SHOWROOM MOVEL`}
            >
              <EventInfo icon={Caravan}>
                <p className="text-wrap text-start">
                  <b>SHOWROOM MÓVEL (carretinha)</b>:{' '}
                  {event.showroom_movel ? 'Sim' : 'Não'}
                </p>
              </EventInfo>
            </PopoverTrigger>
            <DefaultPopoverContent>
              Esse evento <b>{!event.showroom_movel ? 'NÃO' : ''}</b> contará
              com o<b> SHOWROOM MÓVEL</b>
            </DefaultPopoverContent>
          </Popover>

          <Separator className="my-2" />

          <div className="my-2 flex flex-wrap justify-between gap-x-10 gap-y-5">
            <EventInfo icon={User}>
              <p>
                {event.informacoes_solicitante.nome} |{' '}
                {event.informacoes_solicitante.setor}
              </p>
            </EventInfo>
            {event.informacoes_solicitante.email ? (
              <a
                title={`Enviar e-mail para ${event.informacoes_solicitante.nome}`}
                href={`mailto:${event.informacoes_solicitante.email}?subject=${
                  event.nome
                }!&body=${encodeURIComponent(
                  `Olá, ${event.informacoes_solicitante.nome}.<br><br><br><br><b style="color: #4f9d45;">Email obtido via DGT.</b>`,
                )}`}
                className="flex gap-2 font-bold"
              >
                <Mail size={defaultIconSize} />
                <p>{event.informacoes_solicitante.email}</p>
              </a>
            ) : null}
            <a
              title={`Entrar em contato com ${event.informacoes_solicitante.nome}`}
              href={`https://wa.me/55${event.informacoes_solicitante.telefone}`}
              // href={`tel:${event.informacoes_solicitante.telefone}`}
              className="flex gap-2 font-bold"
            >
              <Phone size={defaultIconSize} />
              <p>{event.informacoes_solicitante.telefone}</p>
            </a>
          </div>

          <Separator className="my-2" />

          <ScrollArea
            className="mx-4 max-h-28 rounded-sm pr-5 outline-soft-gray"
            type="auto"
          >
            <p>{event.descricao}</p>
          </ScrollArea>
        </div>
      }
    >
      <div className="mb-8 flex w-full justify-between text-pormade">
        <Popover>
          <PopoverTrigger title={event.nome}>
            <div className="base-text flex w-full items-center gap-3 sm:gap-5">
              <PartyPopper size={35} />

              <p className="max-w-48 truncate font-semibold sm:max-w-[25rem] md:max-w-[28rem] lg:max-w-none">
                <DefaultHighlighter searchWords={search}>
                  {event.nome}
                </DefaultHighlighter>
              </p>
            </div>
          </PopoverTrigger>
          <DefaultPopoverContent>
            <DefaultHighlighter searchWords={search}>
              {event.nome}
            </DefaultHighlighter>
          </DefaultPopoverContent>
        </Popover>

        <div>
          <Popover>
            <PopoverTrigger title={eventStages[event.etapa]?.description}>
              <EventInfo
                icon={eventStages[event.etapa]?.icon}
                iconColor={eventStages[event.etapa]?.color}
                className="items-center"
              >
                <p
                  className={`hidden font-semibold md:block text-${
                    eventStages[event.etapa]?.color
                  }`}
                >
                  {eventStages[event.etapa]?.name}
                </p>
              </EventInfo>
            </PopoverTrigger>
            <DefaultPopoverContent>
              {eventStages[event.etapa]?.description}
            </DefaultPopoverContent>
          </Popover>
        </div>
      </div>

      <div className="grid gap-x-4 gap-y-5">
        {start_date || end_date ? (
          <div className="flex flex-wrap justify-between gap-x-5 gap-y-3">
            {start_date ? (
              <EventInfo icon={Calendar}>
                <p>
                  {start_date} {end_date ? `- ${end_date}` : null}
                </p>
              </EventInfo>
            ) : null}
          </div>
        ) : null}

        {start_time || end_time ? (
          <EventInfo icon={Clock}>
            <p>
              {start_time} {end_time ? `- ${end_time}` : null}
            </p>
          </EventInfo>
        ) : null}

        {event.cidade ? (
          <EventInfo icon={MapPinIcon}>
            <DefaultHighlighter searchWords={search}>
              {event.cidade}
            </DefaultHighlighter>
          </EventInfo>
        ) : (
          ''
        )}
      </div>
    </Frame>
  );
}
