import { cn } from '@/lib/utils';
import { ChevronLeft, ChevronUp, InfoIcon } from 'lucide-react';
import React, { ComponentProps, ReactNode, useEffect, useState } from 'react';
import { To, useNavigate } from 'react-router-dom';
import { Popover, PopoverTrigger, PopoverContent } from './ui/popover';
import { ScrollArea } from './ui/scroll-area';

type DefaultPageProps = ComponentProps<'div'> & {
  className?: string;
  children?: React.ReactNode;
  pageDescription?: {
    title?: string;
    description: string | ReactNode;
  };
  scrollToTopButton?: boolean;
  goBackTo?: To;
  resetScrollOnNavigate?: boolean;
};

export default function DefaultPage({
  children,
  className,
  pageDescription,
  scrollToTopButton = false,
  goBackTo,
  resetScrollOnNavigate,
  ...props
}: DefaultPageProps) {
  const navigate = useNavigate();

  const [scrollYPosition, setScrollYPosition] = useState<number>(0);
  useEffect(() => {
    if (resetScrollOnNavigate) {
      window.scrollTo({ top: 0 });
    }
    window.addEventListener('scroll', () =>
      setScrollYPosition(window.pageYOffset),
    );
  }, []);

  return (
    <div
      {...props}
      className={cn(
        'relative mt-16 flex flex-col items-center px-5 sm:px-10',
        className,
      )}
    >
      {scrollToTopButton ? (
        <button
          type="button"
          title="Ir ao topo da página"
          className={cn(
            'fixed bottom-4 right-4 z-10 flex size-14 items-center justify-center rounded-full bg-muted-foreground/40 text-white backdrop-blur-sm transition-all duration-500 hover:bg-muted-foreground/60 md:size-12',
            scrollYPosition < 200 ? '-bottom-10 opacity-0' : 'opacity-100',
          )}
          onClick={() =>
            window.scroll({
              top: 0,
              behavior: 'smooth',
            })
          }
        >
          <ChevronUp size={40} />
        </button>
      ) : (
        ''
      )}
      {pageDescription ? (
        <Popover>
          <div className="fixed right-4 top-20 z-10">
            <PopoverTrigger title="Toque para ver detalhes">
              <InfoIcon
                className="rounded-full bg-muted-foreground/40 text-white shadow-lg backdrop-blur-sm hover:bg-muted-foreground/60"
                size={35}
              />
            </PopoverTrigger>
            <PopoverContent className="z-10 mt-1 w-full max-w-xs rounded-md bg-dark-gray p-5 text-start text-white shadow-lg backdrop-blur-sm">
              {pageDescription.title ? (
                <p className="mb-3 font-semibold">{pageDescription.title}</p>
              ) : (
                ''
              )}
              <ScrollArea
                scrollHideDelay={300}
                className="h-28 pr-3"
                type="auto"
              >
                {pageDescription.description}
              </ScrollArea>
            </PopoverContent>
          </div>
        </Popover>
      ) : (
        ''
      )}
      {goBackTo ? (
        <button
          type="button"
          title="Voltar"
          onClick={() => navigate(goBackTo)}
          className="fixed left-4 top-20 z-10 self-start rounded-full bg-muted-foreground/40 shadow-lg backdrop-blur-sm hover:bg-muted-foreground/60"
        >
          <ChevronLeft size={40} className="pr-1 text-white" />
        </button>
      ) : null}
      <div className="my-14 flex w-full flex-col items-center">{children}</div>
    </div>
  );
}
