import { useAuth } from '@/contexts/auth.context';
import { useDialog } from '@/hooks/useDialog';
import useLoading from '@/hooks/useLoading';
import { TOriginDetail } from '@/services/api/api.responses';
import { downloadSheet } from '@/services/api/api.utils';
import dayjs from 'dayjs';
import {
  Calendar,
  Download,
  ExternalLinkIcon,
  History,
  Loader,
  MoreVertical,
  Pin,
  Trash,
  Users,
} from 'lucide-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import DownloadSheetButton from './DownloadSheetButton';
import Frame from './Frame';
import DefaultHighlighter from './Highlighter';
import Loading from './Loading';
import ReportList from './ReportList';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './ui/dropdown-menu';
import { Skeleton } from './ui/skeleton';

type OriginCardProps = {
  origin: TOriginDetail;
  search?: string;
  onDeleteOrigin?: (originId: number) => Promise<unknown>;
};

export default function OriginCard({
  origin,
  search,
  onDeleteOrigin,
}: OriginCardProps) {
  // const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  // const settingsButtonRef = useRef<HTMLDivElement>(null);

  const { hasRequiredPermissions } = useAuth();
  const dialog = useDialog();

  const [isRemovingOrigin, setIsRemovingOrigin] = useState<boolean>(false);

  const [isDownloading, startDownload] = useLoading();

  const firstInsertDate = dayjs(origin.data_primeira_insercao).format(
    'DD/MM/YYYY HH:mm',
  );

  const lastInsertDate = dayjs(origin.data_ultima_insercao).format(
    'DD/MM/YYYY HH:mm',
  );

  function handleDownloadSheet(includeAssumed: boolean) {
    return startDownload(() =>
      downloadSheet({ origin, tab: 'Geral', includeAssumed }),
    );
  }

  function handleRemoveOrigin() {
    dialog
      .open({
        type: 'warning',
        title: 'Confirmação de exclusão',
        text: (
          <p className="mt-4 text-base text-muted-foreground">
            Tem certeza que deseja excluir
            <b className="text-pormade-red"> TODOS</b> os leads? Essa ação é
            permanente e{' '}
            <b className="text-pormade-red">não será possível recuperá-los</b>
          </p>
        ),
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar',
      })
      .then((result) => {
        if (result) {
          if (result.isConfirmed) {
            setIsRemovingOrigin(true);
            if (onDeleteOrigin)
              onDeleteOrigin(origin.crm_origem_id).finally(() =>
                setIsRemovingOrigin(false),
              );
          }
        }
      });
  }

  if (isRemovingOrigin) {
    return (
      <Skeleton className="base-text flex h-64 w-full select-none items-center justify-center gap-2 text-muted-foreground data-[visible=false]:hidden">
        <Loading orientation="horizontal" iconSize={40}>
          Excluindo leads
        </Loading>
      </Skeleton>
    );
  }

  return (
    <Frame
      key={origin.crm_origem_id}
      className="bg-white/80 dark:bg-dark-gray/20"
      {...(origin.leads_graficos
        ? {
            moreContent: (
              <div className="mx-auto mt-4 w-full max-w-xl px-1 py-4">
                <ReportList
                  title
                  reports={origin.leads_graficos?.sort(
                    (a, b) => b.quantidade - a.quantidade,
                  )}
                  className="w-full max-w-none"
                />
              </div>
            ),
          }
        : null)}
    >
      <div className="mb-5 flex w-full justify-between text-pormade sm:max-w-none">
        <Link
          to={`/leads/${origin.crm_origem_id}`}
          title={`Acessar página com os leads da origem ${origin.crm_origem_nome}`}
          className="flex gap-2 sm:gap-3"
        >
          <Pin size={30} className="text-pormade" />
          <p className="base-text font-semibold">
            <DefaultHighlighter searchWords={search}>
              {/* ${isDev ? `${origin.crm_origem_id} - ` : ''} */}
              {`${origin.crm_origem_nome}`}
            </DefaultHighlighter>
          </p>
        </Link>

        {hasRequiredPermissions(['excluir_planilhas', 'baixar_planilhas']) ? (
          <DropdownMenu modal={false}>
            <DropdownMenuTrigger className="group transition-colors duration-200">
              <MoreVertical className="text-muted-foreground hover:text-foreground group-data-[state=open]:text-foreground" />
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              className="font-medium transition-colors duration-200"
            >
              <DownloadSheetButton
                request={handleDownloadSheet}
                disabled={isDownloading}
                title={`Baixar planilha ${origin.crm_origem_nome}`}
                className="size-full text-foreground"
              >
                <DropdownMenuItem
                  className="flex size-full gap-2"
                  disabled={isDownloading}
                >
                  {isDownloading ? (
                    <Loader className="animate-spin p-1" />
                  ) : (
                    <Download className="p-1" />
                  )}
                  Baixar
                </DropdownMenuItem>
              </DownloadSheetButton>
              <DropdownMenuItem
                title="Excluir leads"
                className="flex gap-2 text-foreground focus:bg-pormade-red/10 focus:text-pormade-red"
                onClick={handleRemoveOrigin}
              >
                <Trash className="p-1" /> Excluir
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        ) : hasRequiredPermissions(['baixar_planilhas']) ? (
          <DownloadSheetButton
            request={handleDownloadSheet}
            disabled={isDownloading}
            title={`Baixar planilha ${origin.crm_origem_nome}`}
            className="text-muted-foreground transition-colors duration-200 hover:text-pormade"
          >
            {isDownloading ? (
              <Loader className="animate-spin" size={28} />
            ) : (
              <Download size={28} />
            )}
          </DownloadSheetButton>
        ) : hasRequiredPermissions(['excluir_planilhas']) ? (
          <button
            title="Excluir leads"
            className="text-muted-foreground transition-colors duration-200 hover:text-pormade-red"
            onClick={handleRemoveOrigin}
          >
            <Trash size={28} />
          </button>
        ) : (
          ''
        )}
      </div>

      <div className="flex flex-col">
        <div className="my-2 flex flex-wrap items-start gap-3 sm:gap-5">
          <div className="small-text grid gap-5 text-foreground">
            <div className="mt-1 flex items-center gap-3">
              <div className="flex items-center gap-2 font-semibold">
                <Users /> Leads:
              </div>{' '}
              <p>{origin.total_leads}</p>
            </div>
            <div
              title={`Planilha cadasatrada em ${firstInsertDate}`}
              className="flex items-start gap-2 self-start"
            >
              <Calendar className="h-full" />
              <p>
                <b className="font-semibold">Primeiro Registro: </b>
                {firstInsertDate}
              </p>
            </div>
            <div
              data-hidden={!origin.data_ultima_insercao}
              title={`Planilha modificada em ${lastInsertDate}`}
              className="flex items-center gap-2 self-start data-[hidden=true]:hidden"
            >
              <History />
              <p>
                <b className="font-semibold">Último Registro: </b>
                {lastInsertDate}
              </p>
            </div>
          </div>
        </div>

        <Link
          to={`/leads/${origin.crm_origem_id}`}
          title={`Acessar página com os leads da origem ${origin.crm_origem_nome}`}
          className="medium-text mx-auto flex w-fit items-center justify-center gap-2 py-4 text-pormade hover:text-pormade-hover"
        >
          Acessar leads <ExternalLinkIcon />
        </Link>
      </div>
    </Frame>
  );
}
