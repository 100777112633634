import { api } from '@/services/api/api';
import { ChangePictureRequest, LoginRequest } from '@/services/api/api.dto';
import { UserDataResponse } from '@/services/api/api.responses';
import { getAxiosErrorMessage } from '@/services/api/api.utils';
import { AxiosError, AxiosResponse } from 'axios';
import {
  Context,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import myToast from '@/components/Toast';
import useLoading from '@/hooks/useLoading';
import { socket } from '@/services/socket';
import { TUserProfile } from '@/types';
import { Permission } from '@/types/permissions';

type TAuth = {
  isAuthenticated: boolean;
  isLoading: boolean;
  isNetworkError: boolean;
  isServerDown: boolean;
  login: ({ usuario, senha }: LoginRequest) => Promise<unknown>;
  changeProfilePic: (file: File) => Promise<unknown>;
  removeProfilePicture: () => void;
  logout: () => void;
  profile?: TUserProfile;
  hasRequiredPermissions: (requiredPermissions: Permission[]) => boolean;
};

const AuthContext: Context<TAuth> = createContext({} as TAuth);

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [profile, setProfile] = useState<TUserProfile | undefined>();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, startLoading] = useLoading(true);

  const [isNetworkError, setIsNetworkError] = useState(false);
  const [isServerDown, setIsServerDown] = useState(false);

  function hasRequiredPermissions(requiredPermissions: Permission[]) {
    if (profile?.permissions.includes('admin')) return true;
    return requiredPermissions.every((permission) =>
      profile?.permissions.includes(permission),
    );
  }

  function handleChangeProfile(data: UserDataResponse) {
    const { usuario, foto, access_token, permissoes } = data;
    const permissions = permissoes.map(
      (permission) => permission.nome_permissao,
    );

    if (import.meta.env.DEV) {
      api.interceptors.request.use((config) => {
        config.headers.Authorization = access_token;
        return config;
      });
    }

    socket.auth = { token: access_token };
    socket.connect();

    setProfile({
      user: usuario,
      picture: foto,
      permissions,
    });
  }

  function login(loginData: LoginRequest) {
    return new Promise((resolve, reject) => {
      api
        .post<UserDataResponse>('/login', loginData)
        .then((response) => {
          handleChangeProfile(response.data);
          setIsAuthenticated(true);
          resolve(response);
        })
        .catch((error: AxiosError) => {
          myToast.error(
            getAxiosErrorMessage(
              error,
              'Não foi possível efetuar o login. Verifique seu usuário e senha e tente novamente.',
            ),
          );
          reject(error);
        });
    });
  }

  function logout() {
    api
      .delete('/login/sair')
      .then(() => {
        setIsAuthenticated(false);
        setProfile(undefined);
      })
      .catch((error: AxiosError) =>
        myToast.error(
          getAxiosErrorMessage(
            error,
            'Não foi possível sair da conta. Tente novamente mais tarde.',
          ),
        ),
      );
  }

  function changeProfilePic(file: File) {
    return new Promise((resolve, reject) => {
      if (!file.type.includes('image')) {
        myToast.warn('Formato de arquivo invalido! Adicione uma imagem.');
        reject();
        return;
      }
      const formData = new FormData();
      formData.append('foto', file);
      api
        .patch<ChangePictureRequest>('/login/perfil/atualizar/foto', formData)
        .then((response) => {
          myToast.success('Foto de perfil alterada com sucesso!');
          if (profile) {
            const newProfile: TUserProfile = {
              ...profile,
              picture: response.data.foto,
            };
            setProfile(newProfile);
            resolve('');
          }
        })
        .catch(() => {
          myToast.error('Falha ao alterar foto de perfil!');
          reject();
        });
    });
  }

  function removeProfilePicture() {
    api
      .delete('/login/perfil/remover/foto')
      .then(() => {
        myToast.info('Foto de perfil removida com sucesso!');
        if (profile) {
          const newProfile: TUserProfile = {
            ...profile,
            picture: undefined,
          };
          setProfile(newProfile);
        }
      })
      .catch((error) =>
        myToast.error(
          getAxiosErrorMessage(error, 'Falha ao remover foto de perfil!'),
        ),
      );
  }

  function updateNetworkStatus() {
    setIsNetworkError(!navigator.onLine);
  }

  useEffect(() => {
    window.addEventListener('load', updateNetworkStatus);
    window.addEventListener('online', updateNetworkStatus);
    window.addEventListener('offline', updateNetworkStatus);

    return () => {
      window.removeEventListener('load', updateNetworkStatus);
      window.removeEventListener('online', updateNetworkStatus);
      window.removeEventListener('offline', updateNetworkStatus);
    };
  }, [navigator.onLine]);

  useEffect(() => {
    api.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error: AxiosError) => {
        if (error.response?.status === 401) {
          if (socket.connected) socket.disconnect();
          setIsAuthenticated(false);
          setProfile(undefined);
        }

        if (
          error.code === 'ECONNREFUSED' ||
          error.code === 'ENOTFOUND' ||
          error.message.includes('Network Error')
        )
          setIsServerDown(true);

        return Promise.reject(error);
      },
    );

    startLoading(() =>
      api
        .get<UserDataResponse>('/login/perfil')
        .then((response) => {
          handleChangeProfile(response.data);
          setIsAuthenticated(true);
        })
        .catch(() => {
          setIsAuthenticated(false);
        }),
    );
  }, []);

  const value = {
    login,
    logout,
    profile,
    changeProfilePic,
    removeProfilePicture,
    isAuthenticated,
    isLoading,
    isNetworkError,
    isServerDown,
    hasRequiredPermissions,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
