import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import myToast from '@/components/Toast';
import { cn } from '@/lib/utils';
import { api } from '@/services/api/api';
import { getAxiosErrorMessage } from '@/services/api/api.utils';
import { TFileInfo, TSelectItem } from '@/types';

import { HelpCircle } from 'lucide-react';

import { ComboBoxResponsive } from '@/components/ComboBoxResponsive';
import DefaultPage from '@/components/DefaultPage';
import DropFiles from '@/components/DropFiles';
import Subtitle from '@/components/Subtitle';
import Title from '@/components/Title';
import { Button } from '@/components/ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { useNavigation } from '@/contexts/navigation.context';
import {
  TCrmOriginDetailsResponse,
  TCrmOriginsResponse,
} from '@/services/api/api.responses';
import { checkIsMobileDevice } from '@/utils/device.utils';
import { sheetFileToJson } from '@/utils/sheet.utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { isAxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import {
  registerSheetSchema,
  TRegisterSheetSchema,
} from './registerSheet.schema';

type TCrmOrigins = {
  data?: TSelectItem[];
  loading: boolean;
  ok: boolean;
};

export default function RegisterSheet() {
  const { setRouteAccess } = useNavigation();

  const navigate = useNavigate();
  const isMobileDevice = checkIsMobileDevice();
  const { handleSubmit, setValue, formState, watch, clearErrors } =
    useForm<TRegisterSheetSchema>({
      resolver: zodResolver(registerSheetSchema),
      defaultValues: {
        app_crm_origin_id: null,
      },
    });

  const selectedSheetCrmOrigin = watch('sheet_crm_origin_id');
  const selectedAppCrmOrigin = watch('app_crm_origin_id');

  const [filesInfo, setFilesInfo] = useState<TFileInfo[]>([]);
  const [crmOrigins, setCrmOrigins] = useState<TCrmOrigins>({
    loading: true,
    data: [],
    ok: false,
  });
  const [registeredCrmOrigins, setRegisteredCrmOrigins] = useState<TCrmOrigins>(
    {
      loading: true,
      data: [],
      ok: false,
    },
  );

  const [isCheckingOriginStatus, setIsCheckingOriginStatus] =
    useState<boolean>(false);

  const files = useRef<File[]>([]);

  function customRegisterOnChange(
    name: keyof TRegisterSheetSchema,
    value: any,
  ) {
    setValue(name, value, {
      shouldValidate: true,
    });
  }

  function getOrigins() {
    api
      .get<TCrmOriginsResponse>('/crm/origens', {
        params: {
          ordenar: 'nome-asc',
        },
      })
      .then((response) => {
        if (response.data) {
          const filtredCrmOrigins = response.data?.map((origin) => {
            return {
              label: origin.name,
              value: String(origin.id),
            };
          });
          setCrmOrigins({ data: filtredCrmOrigins, ok: true, loading: false });
        } else {
          setCrmOrigins({ data: [], ok: true, loading: false });
        }
      })
      .catch((error) => {
        if (error.response?.status == 404) {
          setCrmOrigins({ data: [], ok: true, loading: false });
          myToast.warn('Nenhuma origem CRM encontrada!');
        } else {
          myToast.error(
            getAxiosErrorMessage(
              error,
              'Não foi possível carregar as origens do CRM. Tente novamente mais tarde.',
            ),
          );
        }
      });
  }

  function getRegisteredOrigins() {
    api
      .get<TCrmOriginDetailsResponse[]>('leads/origens', {
        params: {
          ordenar: 'nome-asc',
        },
      })
      .then((response) => {
        if (response.data) {
          const filtredCrmOrigins = response.data?.map((origin) => {
            return {
              label: origin.crm_origem_nome,
              value: String(origin.crm_origem_id),
            };
          });
          setRegisteredCrmOrigins({
            data: filtredCrmOrigins,
            ok: true,
            loading: false,
          });
        } else {
          setRegisteredCrmOrigins({ data: [], ok: true, loading: false });
        }
      })
      .catch((error) => {
        if (error.response?.status == 404) {
          setRegisteredCrmOrigins({ data: [], ok: true, loading: false });
          // myToast.warn('Nenhuma origem CRM cadastrada!');
        } else {
          myToast.error(
            getAxiosErrorMessage(
              error,
              'Não foi possível carregar as origens do CRM. Tente novamente mais tarde.',
            ),
          );
        }
      });
  }

  function handleUploadFiles(filesToUpload: File[]) {
    filesToUpload = filesToUpload.filter(
      (fileToUpload) =>
        !files.current.find(
          (file) =>
            file.name == fileToUpload.name && file.size == fileToUpload.size,
        ),
    );
    myToast.dismiss('Carregue *planilha(s)*!');
    const currentFilesInfo = filesToUpload.map((file) => {
      files.current.push(file);
      const fileInfo: TFileInfo = {
        name: file.name,
        size: file.size,
      };
      return fileInfo;
    });

    customRegisterOnChange('files', files.current);

    setFilesInfo((old) => [...old, ...currentFilesInfo]);

    filesToUpload?.forEach(async (file) => {
      const tableValues = (await sheetFileToJson(file, 2)) as Array<Object>;
      if (tableValues && tableValues.length <= 0) {
        myToast.warn(
          `A Planilha *"${file.name}"* foi removida pois não possui dados!`,
        );
        handleRemoveFile(file);
      }
    });
  }

  function handleRemoveFile(file: TFileInfo) {
    const fileName = file.name;
    const fileSize = file.size;
    files.current = files.current.filter(
      (oldFile) => oldFile.name != fileName && oldFile.size != fileSize,
    );

    customRegisterOnChange('files', files.current);

    setTimeout(() => {
      setFilesInfo((old) =>
        old.filter(
          (oldFile) => oldFile.name != fileName && oldFile.size != fileSize,
        ),
      );
    }, 200);
  }

  async function submitData(data: TRegisterSheetSchema) {
    try {
      setIsCheckingOriginStatus(true);
      await api.get('/leads/planilhas/status', {
        params: { crm_origem_id: data.sheet_crm_origin_id },
      });
    } catch (error) {
      const errorMessage =
        'Não foi possível prosseguir com o cadastro de planilhas. *Tente novamente mais tarde*.';
      if (isAxiosError(error)) {
        myToast.error(getAxiosErrorMessage(error, errorMessage));
      } else {
        myToast.error(errorMessage);
      }

      return;
    } finally {
      setIsCheckingOriginStatus(false);
    }

    const errors: string[] = [];
    const warns: string[] = [];

    if (errors.length > 0 || warns.length > 0) {
      errors.forEach((error) => myToast.error(error, { autoClose: 6000 }));
      warns.forEach((warn) => myToast.warn(warn));
      return;
    }

    errors.forEach((firedToast) => myToast.dismiss(firedToast));
    nextPage(data);
  }

  function nextPage(data: TRegisterSheetSchema) {
    const finalProcessConfigs: { [key: string]: boolean } = {};

    finalProcessConfigs.segmentar = false;

    setRouteAccess('configSheet', true);
    navigate('/cadastrar-planilha/configuracao', {
      state: {
        ...data,
        sheet_crm_origin_id: Number(data.sheet_crm_origin_id),
      },
    });
  }

  useEffect(() => {
    getOrigins();
    getRegisteredOrigins();
  }, []);

  return (
    <DefaultPage
      goBackTo={'/'}
      pageDescription={{
        title: 'CADASTRO DE PLANILHAS',
        description: (
          <p>
            <b>Cadastre</b> planilhas de leads e <b>vincule-as</b> à uma origem
            do CRM.
          </p>
        ),
      }}
    >
      <Title marginBottom className="w-fit text-center text-foreground">
        <div className="relative">
          <p>Cadastro de Planilhas</p>
        </div>
      </Title>

      <Subtitle className="text-center text-muted-foreground">
        <>
          <b>Insira</b> uma ou mais planilhas com leads
        </>
      </Subtitle>

      <form
        onSubmit={handleSubmit(submitData)}
        className="mt-12 flex w-full max-w-4xl flex-col"
      >
        <div className="mb-10 grid gap-8">
          <ComboBoxResponsive
            disabled={crmOrigins.loading}
            options={crmOrigins.data}
            onChange={(option) => {
              if (option) {
                setValue('sheet_crm_origin_id', option.value);
                clearErrors('sheet_crm_origin_id');
              }
            }}
            onBlur={() =>
              selectedSheetCrmOrigin && clearErrors('sheet_crm_origin_id')
            }
            value={String(selectedSheetCrmOrigin)}
            label="ORIGEM da planilha"
            required
            errorMessage={formState.errors.sheet_crm_origin_id?.message}
          />

          <ComboBoxResponsive
            disabled={registeredCrmOrigins.loading}
            options={registeredCrmOrigins.data}
            onChange={(option) => {
              if (option) {
                if (String(selectedAppCrmOrigin) == String(option.value)) {
                  setValue('app_crm_origin_id', null);
                } else {
                  setValue('app_crm_origin_id', option.value);
                }
                clearErrors('app_crm_origin_id');
              }
            }}
            onBlur={() =>
              selectedAppCrmOrigin && clearErrors('app_crm_origin_id')
            }
            value={String(selectedAppCrmOrigin)}
            label={
              <>
                ORIGEM do Captura de Leads{' '}
                <Popover>
                  <PopoverTrigger>
                    <HelpCircle
                      size={16}
                      className="ml-2 text-muted-foreground"
                    />
                  </PopoverTrigger>
                  <PopoverContent
                    side="top"
                    className="bg-dark-gray text-white"
                  >
                    <p>
                      {' '}
                      Selecione a <b>ORIGEM do Captura de Leads</b> para evitar
                      <b> duplicidade</b> entre os leads da planilha e os leads
                      já cadastrados pelo <b>AutoLeads Mobile</b>.
                    </p>
                  </PopoverContent>
                </Popover>
              </>
            }
            errorMessage={formState.errors.app_crm_origin_id?.message}
          />
        </div>

        <DropFiles
          filesInformation={filesInfo}
          filesInputError={formState.errors.files?.message}
          className={`mt-5 ${
            formState.errors.files?.message ? 'animate-shake' : ''
          }`}
          onUploadFile={(files) => handleUploadFiles(files)}
          onRemoveFile={(file) => handleRemoveFile(file)}
          description={
            isMobileDevice ? (
              <p className="m-auto max-w-lg font-medium">
                <b>Toque</b> para adicionar planilhas
              </p>
            ) : (
              <p className="base-text m-auto max-w-lg font-medium">
                <b>Solte as planilhas</b> aqui, <br />
                ou clique para adicioná-las
              </p>
            )
          }
        />

        <Button
          type="submit"
          className={cn(
            'mx-auto mt-16',
            isCheckingOriginStatus ? 'animate-pulse' : '',
          )}
          disabled={isCheckingOriginStatus}
        >
          {isCheckingOriginStatus ? 'Verificando' : 'Próximo'}
        </Button>
      </form>
    </DefaultPage>
  );
}
