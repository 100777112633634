import Frame from '@/components/Frame';
import { Separator } from '@/components/ui/separator';
import { headersNames } from '@/pages/Sheets/configs/sheet.config';
import { cn } from '@/lib/utils';
import { TCRMInfos } from '@/types/leads';
import { Filter } from 'lucide-react';

type CRMCardProps = {
  crmInfos: TCRMInfos;
};

const hiddenCRMMoreInfos = ['crm_card', 'crm_card_situacao', 'crm_funil'];

const situationColors: { [key: string]: string } = {
  ABERTO: 'pormade-warn',
  REABERTO: 'pormade-warn',
  GANHO: 'pormade',
  PERDIDO: 'pormade-red',
  ENTREGUE: 'pormade-blue',
};

const dateKeyColors: { [key: string]: string } = {
  crm_data_perda: situationColors.PERDIDO,
  crm_data_ganho: situationColors.GANHO,
  crm_data_entregue: situationColors.ENTREGUE,
  crm_motivo_perda: situationColors.PERDIDO,
};

export default function CRMCard({ crmInfos }: CRMCardProps) {
  return (
    <Frame
      moreContent={
        <div className="custom-scrollbar max-h-56 overflow-y-auto">
          {Object.entries(crmInfos).map(([key, value], index) =>
            !hiddenCRMMoreInfos.includes(key) && value != null ? (
              <div
                className={cn(
                  dateKeyColors[key] &&
                    `text-${dateKeyColors[key]} font-medium`,
                )}
                key={`${crmInfos.crm_card}-${index}`}
              >
                <p>
                  {headersNames[String(key)] ?? key}:{' '}
                  <b className="font-semibold">{value}</b>
                </p>
                <Separator className="my-2" />
              </div>
            ) : (
              ''
            ),
          )}
        </div>
      }
    >
      <div className="flex justify-between">
        <div className="grid gap-1">
          <p className="small-text font-semibold">{crmInfos.crm_nome}</p>
          <p className="medium-text font-medium">
            Card: <b className="font-semibold">#{crmInfos.crm_card}</b>
          </p>
        </div>
        <div className="grid gap-2 text-end">
          <p
            className={cn(
              crmInfos.crm_card_situacao &&
                `text-${situationColors[String(crmInfos.crm_card_situacao)]}`,
              'font-semibold',
            )}
          >
            {crmInfos.crm_card_situacao}
          </p>
        </div>
      </div>
      <div className="mt-3 flex w-fit items-center gap-2 rounded-md bg-pormade px-2 py-1 text-white">
        <div className="flex items-center gap-1">
          <Filter /> <p>Funil:</p>
        </div>
        <p className="font-semibold">{crmInfos.crm_funil}</p>
      </div>
    </Frame>
  );
}
