import PormadeLogo from '@/assets/pormade.svg';
import { useAuth } from '@/contexts/auth.context';
import { useDialog } from '@/hooks/useDialog';
import { cn } from '@/lib/utils';
import { getAuthorizedRoutes } from '@/routes/routes.config';
import { User } from 'lucide-react';
import { Link } from 'react-router-dom';
import ProfileSideBar from '../ProfileSideBar';
import ToggleThemeButton from '../ToggleThemeButton';
import { NavBar } from './NavBar';
import SideBar from './SideBar';

export default function Header() {
  const {
    isAuthenticated,
    logout,
    profile,
    changeProfilePic,
    removeProfilePicture,
    isServerDown,
    isNetworkError,
  } = useAuth();

  const dialog = useDialog();

  const authorizedRoutes = getAuthorizedRoutes(profile);

  function handleLogout() {
    dialog
      .open({
        type: 'question',
        title: 'Sair?',
        text: 'Você realmente deseja sair da sua conta?',
        confirmButtonText: 'Sair',
        cancelButtonText: 'Cancelar',
        closeButton: true,
      })
      .then((response) => {
        if (response && response.isConfirmed) {
          logout();
        }
      });
  }

  if (authorizedRoutes && isAuthenticated && !isServerDown && !isNetworkError)
    return (
      <div className="fixed top-0 z-20 flex h-16 w-full items-center justify-between border-b border-pormade bg-black/75 px-6 py-4 backdrop-blur-sm">
        <SideBar
          className={cn(!isAuthenticated ? 'hidden' : '', 'lg:hidden')}
          contents={authorizedRoutes}
        />
        {isAuthenticated ? (
          <Link
            to={'/'}
            className="h-full cursor-pointer select-none"
            title="Clique para voltar à tela inicial"
          >
            <img src={PormadeLogo} className="h-full min-w-20" />
          </Link>
        ) : (
          <img src={PormadeLogo} className="h-full min-w-20" />
        )}

        <NavBar isAuthenticated={isAuthenticated} contents={authorizedRoutes} />

        <div className="center-center relative flex items-center">
          <div className="center-center absolute bottom-0 right-16 top-0 z-10 my-auto self-center">
            <ToggleThemeButton />
          </div>

          <ProfileSideBar
            isAuthenticated={isAuthenticated}
            onLogout={handleLogout}
            profile={profile}
            changeProfilePic={changeProfilePic}
            removeProfilePicture={removeProfilePicture}
          >
            <div
              className="center-center relative size-9 rounded-full bg-gray bg-cover bg-center bg-no-repeat ring-2 ring-pormade"
              style={{ backgroundImage: `url(${profile?.picture})` }}
            >
              <User
                data-hidden={isAuthenticated && profile?.picture ? true : false}
                className="size-[80%] text-white data-[hidden=true]:hidden"
              />
            </div>
          </ProfileSideBar>
        </div>
      </div>
    );
}
