import { useEffect, useRef, useState } from 'react';

import { cn } from '@/lib/utils';
import { TFileInfo } from '@/types';
import { Upload } from 'lucide-react';
import myToast from '../Toast';
import { Input } from '../ui/input';
import FileCards from './FileCards';

type DropFilesProps = {
  title?: string;
  className?: string;
  acceptFormats?: Array<string>;
  filesInputError?: string;
  filesInformation: TFileInfo[];
  onUploadFile: (files: File[]) => void;
  onRemoveFile: (file: TFileInfo) => void;
  description?: React.ReactNode | string;
  max?: number;
};
export default function DropFiles({
  title,
  className,
  acceptFormats,
  onUploadFile,
  onRemoveFile,
  filesInputError,
  filesInformation,
  description,
  max,
}: DropFilesProps) {
  const fileFormats = acceptFormats ?? [
    '.xlsm',
    '.xlsb',
    '.odf',
    '.ods',
    '.odt',
    '.csv',
    '.csvs',
    'text/csv',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];

  const [hasDragOver, setHasDragOver] = useState(false);
  const dropFilesRef = useRef<HTMLDivElement>(null);

  const filterFilesFormats = (inputFiles: Array<File>) => {
    let unsuportedFilesExtensions: Array<string> = [];
    let supportedFiles: Array<File> = [];

    inputFiles?.forEach((file) => {
      const parts = file.name.split('.');
      const extension = `.${parts[parts.length - 1]}`;
      if (
        fileFormats.includes(extension) ||
        (fileFormats.includes(file.type) && extension !== '.xls')
      ) {
        supportedFiles.push(file);
        // if (
        //   !inputFiles?.find(
        //     fileInArray =>
        //       fileInArray?.name == file?.name && fileInArray?.size == file?.size
        //   )
        // ) {
        // }
      } else {
        if (!unsuportedFilesExtensions.includes(extension)) {
          unsuportedFilesExtensions.push(extension);
        }
      }
    });

    if (unsuportedFilesExtensions.length > 0) {
      makeShakeAnimation();

      if (unsuportedFilesExtensions.length > 1) {
        myToast.error(
          `Formatos *${unsuportedFilesExtensions.join(', ')}* não suportados!`,
        );
      } else {
        myToast.error(
          `Formato *${unsuportedFilesExtensions.join(', ')}* não suportado!`,
        );
      }
      if (unsuportedFilesExtensions.includes('.xls')) {
        myToast.error(`Salve a planilha no formato *.XLSX*`);
      }
    }
    return supportedFiles;
  };

  const handleOnUpload = (inputFiles: FileList | null) => {
    if (inputFiles) {
      const inputFilesList = Array.from(inputFiles);

      if (inputFilesList.length > 0) {
        const filtredFiles = filterFilesFormats(inputFilesList);

        if (max) {
          if (filtredFiles.length > max) {
            if (max > 1) {
              myToast.error(`Apenas *${max}* arquivos são permitidos!`);
            } else {
              myToast.error(`Apenas *${max}* arquivo é permitido!`);
            }
          } else {
            if (inputFiles.length >= max) {
              if (max > 1) {
                myToast.error(`Apenas *${max}* arquivos são permitidos!`);
              } else {
                myToast.error(`Apenas *${max}* arquivo é permitido!`);
              }
            } else {
              onUploadFile(filtredFiles.splice(0, max));
              // setFiles([...files, ...filesInfos].splice(0, max));
            }
          }
        } else {
          onUploadFile(filtredFiles);
          // setFiles([...files, ...filesInfos]);
        }
      }
    }
  };

  useEffect(() => {
    if (filesInformation.length == 0) {
      const inputFile = document.querySelector(
        '#inputFile',
      ) as HTMLInputElement;

      if (inputFile) {
        inputFile.value = '';
      }
    }
  }, [filesInformation]);

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e?.dataTransfer;
    handleOnUpload(files);
    setHasDragOver(false);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = () => {
    setHasDragOver(true);
  };

  const handleDragLeave = () => {
    setHasDragOver(false);
  };

  const makeShakeAnimation = () => {
    dropFilesRef.current?.classList?.add('animate-shake');
    setTimeout(() => {
      dropFilesRef.current?.classList?.remove('animate-shake');
    }, 200);
  };

  return (
    <>
      {title && (
        <p className="subtitle m-auto mb-4 mt-10 w-full max-w-6xl font-semibold">
          {title}
        </p>
      )}

      <div ref={dropFilesRef} title="Adicionar arquivo(s)">
        <div
          className={`${className ?? ''} ${hasDragOver && 'scale-[102%]'} ${
            filesInformation && filesInformation.length > 0 ? 'h-44' : 'h-72'
          } center-center relative mx-auto w-full max-w-6xl transition-all delay-100 duration-500`}
        >
          <>
            <span
              className={`center-center absolute inset-0 z-[5] flex cursor-pointer flex-col rounded-3xl border border-muted-foreground bg-soft-gray/50 outline-dashed outline-2 -outline-offset-[20px] dark:bg-foreground/10 ${
                hasDragOver
                  ? 'shadow-xl shadow-pormade/20 outline outline-2 outline-pormade-hover dark:shadow-pormade-hover/20'
                  : 'outline-muted-foreground dark:outline-muted-foreground'
              } ${
                filesInputError &&
                !hasDragOver &&
                'border border-pormade-red/70 outline-pormade-red/80 dark:outline-pormade-red/80'
              }`}
            >
              <div
                className={`center-center flex flex-col text-muted-foreground`}
              >
                <Upload
                  className={cn(
                    filesInformation.length > 0 || hasDragOver
                      ? 'text-pormade'
                      : '',
                    'size-14 animate-littleBounce transition-colors sm:size-20',
                  )}
                />
                <div
                  className={`subtitle -z-10 select-none px-10 text-center font-semibold ${
                    filesInformation && filesInformation.length <= 0
                      ? 'mt-3 delay-200'
                      : 'fixed opacity-0 delay-0'
                  } transition-all duration-0`}
                >
                  {hasDragOver ? (
                    <>
                      <b className="font-bold text-pormade">
                        Solte o(s) arquivo(s)
                      </b>
                      .
                    </>
                  ) : (
                    <>{description}</>
                  )}
                </div>
              </div>
            </span>
          </>
          <label
            className={`center-center z-10 m-4 flex h-full w-full cursor-pointer flex-col rounded-xl p-11`}
            onDrop={(e) => handleDrop(e)}
            onDragOver={(e) => handleDragOver(e)}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
          >
            <Input
              multiple
              id="inputFile"
              type={'file'}
              accept={fileFormats.toString()}
              className={'hidden'}
              onChange={(event) => handleOnUpload(event.target.files)}
            />
          </label>
        </div>
      </div>
      <span className="mt-2 font-medium text-pormade-red">
        {filesInputError}
      </span>
      <div
        className={cn(
          'mt-2 transition-all delay-100 duration-500',
          filesInformation && filesInformation.length > 0
            ? 'translate-y-0 rounded-lg border-2 border-soft-gray'
            : 'size-0 -translate-y-[130%] opacity-0',
        )}
      >
        <div className="custom-scrollbar mt-5 max-h-56 overflow-y-auto">
          <FileCards
            files={filesInformation}
            removeFile={(file) => onRemoveFile(file)}
          />
        </div>

        {filesInformation.length > 0 ? (
          <p className="medium-text px-5 py-3 text-end font-semibold text-pormade transition-all duration-500">
            Total: {filesInformation.length}
          </p>
        ) : (
          ''
        )}
      </div>
    </>
  );
}
