import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  ArrowUpDown,
  ChevronLeft,
  ChevronRight,
  ChevronRightIcon,
  ChevronsLeft,
  ChevronsRight,
  SearchX,
} from 'lucide-react';

import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { cn } from '@/lib/utils';
// import Tooltip from './Tooltip';
import { ReactNode, useState } from 'react';
import DefaultHighlighter from './Highlighter';
import TrashButton from './TrashButton';

import { processSheetData } from '@/utils/sheet.utils';
import { ScrollArea } from './ui/scroll-area';

type DataTableProps = {
  title?: ReactNode;
  data: object[];
  sort?: boolean;
  sheetRows?: number;
  pagination?: boolean;
  showTotal?: boolean;
  outline?: boolean;
  filterInput?: boolean;
  filterColumns?: boolean;
  onRemove?: () => void;
  headerElement?: (index: number) => React.ReactNode;
};
export function DataTable({
  title,
  data,
  filterInput = false,
  filterColumns = false,
  sort = false,
  sheetRows = 7,
  outline = false,
  showTotal = true,
  pagination = true,
  headerElement,
  onRemove,
}: DataTableProps) {
  const processedData: object[] = processSheetData(data);

  const headers = Object?.keys(processedData[0]);

  const columns: ColumnDef<any>[] = headers.map((header, i) => {
    let headerConfig;
    if (sort) {
      headerConfig = ({ column }: any) => (
        <>
          {headerElement ? headerElement(i) : ''}
          <Button
            variant="ghost"
            className={cn(
              'my-1 w-full text-sm sm:my-2 sm:text-base',
              column.getIsSorted()
                ? 'bg-muted-foreground/30 text-foreground hover:bg-muted-foreground/40'
                : 'hover:bg-muted-foreground/10',
            )}
            onClick={() => {
              column.toggleSorting(column.getIsSorted() === 'asc');
            }}
          >
            {header}
            <ArrowUpDown
              className={cn(
                'ml-2 size-5',
                column.getIsSorted() == 'desc' ? '-scale-y-100' : '',
              )}
            />
          </Button>
        </>
      );
    } else {
      headerConfig = () => (
        <div className="flex flex-col">
          {headerElement ? headerElement(i) : ''}
          <p className="p-4 font-semibold text-dark-gray">{header}</p>
        </div>
      );
    }

    return {
      accessorKey: header,
      accessorFn: (row) => row[header],
      header: headerConfig,
      cell: ({ row }) => (
        <>
          <div className="truncate">
            <DefaultHighlighter searchWords={globalFilter}>
              {String(row.getValue(header))}
            </DefaultHighlighter>
          </div>
        </>
      ),
    };
  });

  const [sorting, setSorting] = useState<SortingState>([
    { id: headers[0], desc: false },
  ]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = useState({});

  const table = useReactTable({
    data: processedData,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    autoResetPageIndex: false,
    initialState: {
      pagination: {
        pageSize: sheetRows,
      },
    },
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      globalFilter,
    },
  });

  return (
    <div className="w-full">
      <div className="flex flex-wrap-reverse items-center justify-between gap-x-10 gap-y-5 py-4">
        {title ? title : ''}
        {filterInput ? (
          <div className="w-full max-w-60 sm:max-w-80">
            <Input
              id="dataTableSearchInput"
              title="Busque por dados específicos na planilha"
              placeholder="Buscar por dados..."
              value={globalFilter}
              onChange={(event) =>
                setGlobalFilter(event.target.value.limitSpaces())
              }
              className="mx-auto w-full border-dark-gray focus:border-soft-gray md:mx-0"
            />
          </div>
        ) : null}

        {filterColumns ? (
          <DropdownMenu>
            <DropdownMenuTrigger asChild className="group">
              <Button
                variant="outline"
                className="group mx-auto ml-auto select-none md:mx-0"
              >
                Colunas
                <ChevronRightIcon className="ml-2 h-4 w-4 transition-transform duration-200 group-data-[state=open]:rotate-90" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <ScrollArea className="h-40">
                {table
                  .getAllColumns()
                  .filter((column) => column.getCanHide())
                  .map((column) => {
                    return (
                      <DropdownMenuCheckboxItem
                        key={column.id}
                        className="capitalize"
                        checked={column.getIsVisible()}
                        onCheckedChange={(value) =>
                          column.toggleVisibility(!!value)
                        }
                        onSelect={(event) => event.preventDefault()}
                      >
                        {column.id}
                      </DropdownMenuCheckboxItem>
                    );
                  })}
              </ScrollArea>
            </DropdownMenuContent>
          </DropdownMenu>
        ) : (
          ''
        )}

        {onRemove ? (
          <div
            className={cn(
              'ml-5',
              !filterColumns && !filterInput && 'flex justify-end self-end',
            )}
          >
            <TrashButton title="Excluir planilha" onRemove={onRemove} />
          </div>
        ) : (
          ''
        )}
      </div>

      <div className="rounded-md border border-muted-foreground/40">
        <Table>
          {table.getRowModel().rows?.length ? (
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead
                        key={header.id}
                        className={cn(
                          outline
                            ? 'outline outline-1 outline-muted-foreground/20'
                            : '',
                        )}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
          ) : (
            ''
          )}
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      className={cn(
                        'max-w-72 text-xs sm:text-sm',
                        outline
                          ? 'outline outline-1 outline-muted-foreground/20'
                          : '',
                      )}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="medium-text h-44 text-center"
                >
                  <div className="flex items-center justify-center gap-2 text-muted-foreground">
                    <SearchX /> Busca sem resultados
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      <div className="flex flex-wrap-reverse items-end justify-center gap-4 space-x-2 truncate py-2">
        {showTotal ? (
          <div className="text-md mt-1 flex-1 text-dark-gray">
            <b>Total:</b> {table.getRowCount()}
          </div>
        ) : (
          ''
        )}

        {pagination && table.getPageCount() > 1 ? (
          <div className="flex items-center gap-2 py-3 text-dark-gray">
            <Button
              title="Primeira página"
              variant="outline"
              className="select-none"
              size="sm"
              onClick={() => table.firstPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <ChevronsLeft />
            </Button>
            <Button
              title="Página anterior"
              variant="outline"
              className="select-none"
              size="sm"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <ChevronLeft />
            </Button>
            <p>
              {table.getState().pagination.pageIndex + 1} de{' '}
              {table.getPageCount()}
            </p>
            <Button
              title="Próxima página"
              variant="outline"
              className="select-none"
              size="sm"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              <ChevronRight />
            </Button>
            <Button
              title="Última página"
              variant="outline"
              className="select-none"
              size="sm"
              onClick={() => table.lastPage()}
              disabled={!table.getCanNextPage()}
            >
              <ChevronsRight />
            </Button>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
