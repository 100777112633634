import ErrorPage from '@/components/ErrorPage';
import { useAuth } from '@/contexts/auth.context';
import { Permission } from '@/types/permissions';
import { LockKeyhole } from 'lucide-react';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export type ProtectedRouteProps = {
  children: ReactNode;
  requiredPermissions?: Permission[];
  redirectTo?: string;
};

const defaultRedirectTime = 5;

export default function ProtectedRoute({
  requiredPermissions,
  children,
  redirectTo = '/',
}: ProtectedRouteProps) {
  const { hasRequiredPermissions } = useAuth();
  const location = useLocation();

  const navigateTo = useNavigate();

  const [redirectTimer, setRedirectTimer] =
    useState<number>(defaultRedirectTime);

  const redirectInterval = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (redirectTimer <= 0) {
      clearInterval(redirectInterval.current);
      setRedirectTimer(defaultRedirectTime);
      navigateTo(redirectTo);
    }
  }, [redirectTimer]);

  useEffect(() => {
    if (requiredPermissions && !hasRequiredPermissions(requiredPermissions)) {
      redirectInterval.current = setInterval(() => {
        setRedirectTimer((prev) => prev - 1);
      }, 1000);
    }
  }, [location]);

  useEffect(() => {
    return () => {
      clearInterval(redirectInterval.current);
    };
  }, []);

  const notAuthorizedComponent = (
    <ErrorPage
      icon={LockKeyhole}
      title="Não autorizado"
      subtitle="Você não tem permissão para acessar essa página"
      description={`Voltando em ${redirectTimer} ...`}
      buttonNavigateTo={redirectTo}
      navigateButtonText="Voltar"
    />
  );

  if (requiredPermissions && !hasRequiredPermissions(requiredPermissions)) {
    return notAuthorizedComponent;
  }

  return children;
}
