import { ColumnDef } from '@tanstack/react-table';
import { User } from 'lucide-react';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '../ui/hover-card';
import CheckboxStatus from './CheckboxStatus';
import { TLeadObject } from './types';

type StatusColumnProps = {
  isAbleToChangeStatus: (lead: TLeadObject) => boolean;
  showCheckAll?: boolean;
  isAbleToCheckAll?: boolean;
  isAllChecked?: boolean;
  handleCheckedChange: (lead: TLeadObject) => Promise<unknown>;
  handleCheckAll: () => Promise<unknown>;
};
export function StatusColumn({
  isAbleToChangeStatus,
  handleCheckedChange,
  isAbleToCheckAll,
  handleCheckAll,
  showCheckAll,
  isAllChecked,
}: StatusColumnProps): ColumnDef<any, any> {
  return {
    id: 'status',
    header: () =>
      showCheckAll ? (
        <div className="pr-6">
          <CheckboxStatus
            id={'row.id'}
            title={
              isAllChecked
                ? 'Deixar de assumir todos os leads'
                : 'Assumir todos os leads'
            }
            checked={!!isAllChecked}
            disabled={!isAbleToCheckAll}
            onCheckedChange={handleCheckAll}
            confirmation={{
              title: isAllChecked
                ? 'Deixar de assumir todos os leads?'
                : 'Assumir todos os leads?',
              description: isAllChecked
                ? 'Deseja deixar de assumir todos os leads que você assumiu?'
                : 'Deseja assumir todos os leads não assumidos?',
            }}
          />
        </div>
      ) : (
        'Assumir'
      ),
    cell: ({ row }) => (
      <div className="grid grid-cols-2">
        <HoverCard>
          <HoverCardTrigger>
            <CheckboxStatus
              id={row.id}
              checked={row.original.tratado}
              disabled={!isAbleToChangeStatus(row.original)}
              onCheckedChange={() => handleCheckedChange(row.original)}
            />
          </HoverCardTrigger>
          {row.original.tratado ? (
            <HoverCardContent>
              <p className="mb-2">Lead assumido por:</p>
              <div className="flex items-center gap-2">
                <User />
                {row.original.tratado_usuario}
              </div>
            </HoverCardContent>
          ) : (
            ''
          )}
        </HoverCard>
      </div>
    ),
    enableSorting: false,
    enableHiding: false,
  };
}
