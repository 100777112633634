import myToast from '@/components/Toast';
import { useDebounce } from '@/hooks/useDebounce';
import { api } from '@/services/api/api';
import { getAxiosErrorMessage } from '@/services/api/api.utils';
import { TEvent, TOrderBy } from '@/types';
import { useEffect, useRef, useState } from 'react';

import { Frown } from 'lucide-react';

import { DatePickerRange } from '@/components/DateRangePicker';
import DefaultPage from '@/components/DefaultPage';
import LazyLoadComponent from '@/components/LazyLoadComponent';
import OrderBy from '@/components/OrderBy';
import SearchInput from '@/components/SearchInput';
import Subtitle from '@/components/Subtitle';
import Title from '@/components/Title';
import VirtualScroll from '@/components/VirtualScroll';
import { Skeleton } from '@/components/ui/skeleton';
import { EventsResponse } from '@/services/api/api.responses';
import { DateRange } from 'react-day-picker';
import EventCard from './EventCard';

const orderByConfig: TOrderBy[] = [
  { selected: false, name: 'Nome', order: 'asc' },
  { selected: true, name: 'Data', order: 'asc' },
];

export default function Events() {
  const [events, setEvents] = useState<TEvent[]>([]);
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [orderBy, setOrderBy] = useState<TOrderBy[]>(orderByConfig);
  const [canLoadMorePages, setCanLoadMorePages] = useState<boolean>(false);
  const [notFoundEvents, setNotFoundEvents] = useState<boolean>(false);
  const [isLoadingEvents, setIsLodingEvents] = useState<boolean>(false);
  const [totalItems, setTotalItems] = useState<number>(0);

  const [selectedDateRange, setSelectedDateRange] = useState<
    DateRange | undefined
  >({} as DateRange);

  const debouncedQuery = useDebounce(query);

  const isSearchingEvents = useRef<boolean>(false);
  const currentPage = useRef<number>(1);
  const availablePages = useRef<number>(0);
  const isTypingTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const requestController = useRef<AbortController>();

  function handleChangeQuery(value: string) {
    if (isTypingTimeoutRef.current) {
      clearTimeout(isTypingTimeoutRef.current);
    }

    setQuery(value.limitSpaces());
    if (!isTyping && value.trim()) {
      setIsTyping(true);
      setCanLoadMorePages(false);
    }
    isTypingTimeoutRef.current = setTimeout(() => {
      setIsTyping(false);
    }, 600);
  }

  function getEvents(page?: number) {
    if (isSearchingEvents.current && requestController.current) {
      requestController.current.abort();
    }

    requestController.current = new AbortController();

    isSearchingEvents.current = true;
    setCanLoadMorePages(false);
    setNotFoundEvents(false);

    const start_date = selectedDateRange?.from?.apiDateFormat();
    const end_date = selectedDateRange?.to?.apiDateFormat();
    const selectedOrderBy = orderBy.find((item) => item.selected);

    setIsLodingEvents(true);
    api
      .get<EventsResponse>('/buscas/eventos/aprovados', {
        signal: requestController.current.signal,
        params: {
          pagina: page ?? currentPage.current,
          pesquisa: debouncedQuery ?? query ?? '',
          ordenar: selectedOrderBy
            ? `${selectedOrderBy.name.toLowerCase()}-${selectedOrderBy.order}`
            : 'data-desc',
          ...(end_date && start_date ? { data_hora_inicio: start_date } : ''),
          ...(start_date && end_date ? { data_hora_fim: end_date } : ''),
        },
      })
      .then((response) => {
        availablePages.current = response.data.total_paginas;
        setTotalItems(response.data.TOTAL_ITENS);
        const apiEvents: TEvent[] = response.data.eventos;
        setEvents((old) =>
          page && page > currentPage.current && old.length > 0
            ? [...old, ...apiEvents]
            : apiEvents,
        );
      })
      .catch((error) => {
        if (error?.response?.status == 404) {
          setEvents([]);
          setCanLoadMorePages(false);
          setNotFoundEvents(true);
        } else if (error.message != 'canceled') {
          myToast.error(
            getAxiosErrorMessage(error, 'Não foi possível carregar os eventos'),
          );
        }
      })
      .finally(() => {
        if (page && page != currentPage.current) {
          currentPage.current = page;
        }
        isSearchingEvents.current = false;
        setIsLodingEvents(false);
      });
  }

  function handleOnLoadMoreEvents() {
    if (
      events.length > 0 &&
      !isSearchingEvents.current &&
      currentPage.current < availablePages.current
    ) {
      getEvents(currentPage.current + 1);
    } else {
      setCanLoadMorePages(false);
    }
  }

  function handleChangeOrderBy(orderBy: TOrderBy[]) {
    setEvents([]);
    setOrderBy(orderBy);
  }

  useEffect(() => {
    setTimeout(() => {
      if (currentPage.current && availablePages.current)
        setCanLoadMorePages(currentPage.current < availablePages.current);
    }, 200);
  }, [events]);

  useEffect(() => {
    setEvents([]);
    getEvents(1);
  }, [debouncedQuery, orderBy]);

  useEffect(() => {
    if (
      (selectedDateRange?.from && selectedDateRange.to) ||
      selectedDateRange == undefined
    ) {
      setEvents([]);
      getEvents(1);
    }
  }, [selectedDateRange]);

  return (
    <DefaultPage
      scrollToTopButton
      goBackTo="/"
      className="relative mx-auto w-full max-w-6xl"
      pageDescription={{
        title: 'FEIRAS E EVENTOS',
        description: (
          <div>
            Acompanhe <b>feiras e eventos</b> aprovadas pela direção.
          </div>
        ),
      }}
    >
      <header className="grid w-full">
        <Title marginBottom className="text-center">
          Feiras e Eventos
        </Title>
        <Subtitle className="mb-16 text-center">Eventos cadastrados</Subtitle>
      </header>
      <SearchInput
        value={query}
        onChangeValue={handleChangeQuery}
        placeholder="Busque por eventos ..."
      />
      <div className="mt-12 flex w-full flex-wrap justify-center gap-5 sm:justify-between">
        <OrderBy items={orderBy} onChange={handleChangeOrderBy} />
        <DatePickerRange
          setDate={setSelectedDateRange}
          date={selectedDateRange}
          onSelect={setSelectedDateRange}
          initialCurrentMounthRange
          currentMounthButton
        />
      </div>
      {totalItems && !isLoadingEvents && events.length > 0 ? (
        <div className="mt-10 self-start">
          <p className="medium-text font-medium">
            Eventos encontrados: {totalItems}
          </p>
        </div>
      ) : null}
      <VirtualScroll
        canLoadMore={canLoadMorePages}
        loadMore={handleOnLoadMoreEvents}
      >
        <section
          data-hidden={events.length == 0 || isTyping}
          className="mt-20 grid gap-24 data-[hidden=true]:hidden"
        >
          {events.map((event, index) => (
            <LazyLoadComponent
              key={`${event.id_evento}-${index}`}
              observerMargin="800px"
            >
              <EventCard search={debouncedQuery} event={event} />
            </LazyLoadComponent>
          ))}
        </section>
      </VirtualScroll>
      <div
        data-visible={isLoadingEvents || isTyping}
        className="mt-20 grid w-full gap-24 data-[visible=false]:hidden"
      >
        <Skeleton className="h-[18.5rem] w-full shadow-md" />
        <Skeleton className="h-[18.5rem] w-full shadow-md" />
      </div>

      {notFoundEvents ? (
        <Skeleton
          data-visible={notFoundEvents}
          className="base-text mt-20 flex h-[18.5rem] w-full items-center justify-center gap-2 text-dark-gray shadow-md data-[visible=false]:hidden"
        >
          <div className="flex flex-wrap justify-center gap-2 px-10">
            <Frown size={35} />{' '}
            <p className="text-center">Nenhum evento encontrado</p>
          </div>
        </Skeleton>
      ) : (
        <div
          data-visible={events.length == 0 || isTyping || canLoadMorePages}
          className="mt-20 grid w-full gap-24 data-[visible=false]:hidden"
        >
          <Skeleton className="base-text flex h-[18.5rem] w-full items-center justify-center gap-2 text-dark-gray shadow-md" />

          <Skeleton
            data-visible={!notFoundEvents}
            className="h-[18.5rem] shadow-md data-[visible=false]:hidden"
          />
        </div>
      )}
    </DefaultPage>
  );
}
