import { Link, useLocation } from 'react-router-dom';
import { SheetDescription } from '../../ui/sheet';
import { TSideBarItem } from '../../../types';
import { cn } from '@/lib/utils';

type SideBarItemProps = TSideBarItem & {
  onClick?: (event: React.MouseEvent) => void;
  large?: boolean;
  className?: string;
};

export default function SideBarGroupItem({
  title,
  href,
  icon,
  onClick,
  className,
  large = false,
}: SideBarItemProps) {
  const { pathname } = useLocation();

  function checkIfIsSelected(href?: string) {
    const locationPaths = pathname.split('/').filter((path) => path);

    if (!href) return false;
    if (pathname == href) return true;
    return locationPaths.includes(href.replace('/', ''));
  }

  const Icon = icon || null;
  return (
    <Link to={href} onClick={onClick} className="group">
      <SheetDescription
        data-selected={checkIfIsSelected(href)}
        className={` ${
          large ? 'gap-4 text-xl' : 'gap-3 text-lg'
        } flex cursor-pointer select-none items-start justify-start border-gray text-left text-white ${className} transition-colors duration-100 group-hover:text-pormade data-[selected=true]:text-pormade`}
      >
        {Icon ? (
          <Icon className={cn(` ${large ? 'size-7' : 'size-5'}, mt-1`)} />
        ) : (
          ''
        )}
        <span className="text-wrap leading-7">{title}</span>
      </SheetDescription>
    </Link>
  );
}
