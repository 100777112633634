import { cn } from '@/lib/utils';
import { ColumnDef } from '@tanstack/react-table';
import { ArrowUpDown } from 'lucide-react';
import {
  cellsNames,
  headersNames,
} from '../../pages/Sheets/configs/sheet.config';
import CopyButton from '../CopyButton';
import DefaultHighlighter from '../Highlighter';
import { Button } from '../ui/button';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '../ui/hover-card';

type ColumnsProps = {
  headers: string[];
  sort: boolean;
  headerElement?: (index: number) => React.ReactNode;
  globalFilter: string;
};

export function Columns({
  headers,
  sort,
  headerElement,
  globalFilter,
}: ColumnsProps): ColumnDef<any>[] {
  return headers.map((columnName, i) => {
    let headerConfig;
    if (sort) {
      headerConfig = ({ column }: any) => (
        <>
          {headerElement ? headerElement(i) : ''}
          <Button
            variant="ghost"
            className={cn(
              'transition-color my-1 w-full px-3 text-sm duration-200 sm:my-2 sm:text-base',
              column.getIsSorted()
                ? 'bg-pormade text-white hover:bg-pormade-hover hover:text-white'
                : 'hover:bg-muted-foreground/10',
            )}
            onClick={() => {
              column.toggleSorting(column.getIsSorted() === 'asc');
            }}
          >
            {headersNames[columnName] || columnName}
            <ArrowUpDown
              className={cn(
                'ml-2 size-5',
                column.getIsSorted() == 'desc' ? '-scale-y-100' : '',
              )}
            />
          </Button>
        </>
      );
    } else {
      headerConfig = () => (
        <div className="flex flex-col">
          {headerElement ? headerElement(i) : ''}
          <p className="p-4 font-semibold text-muted-foreground">
            {columnName}
          </p>
        </div>
      );
    }

    return {
      accessorKey: columnName,
      accessorFn: (row) => row[columnName],
      header: headerConfig,
      cell: ({ row }) => (
        <>
          <div className="truncate">
            <HoverCard>
              <HoverCardTrigger>
                <DefaultHighlighter searchWords={globalFilter}>
                  {cellsNames[String(row.getValue(columnName))] ??
                    (row.getValue(columnName)
                      ? String(row.getValue(columnName))
                      : '')}
                </DefaultHighlighter>
              </HoverCardTrigger>
              <HoverCardContent
                className="w-full"
                onClick={(event) => event.stopPropagation()}
              >
                <div className="flex w-full items-center justify-between gap-5 text-wrap">
                  <p>
                    {cellsNames[String(row.getValue(columnName))] ??
                      (row.getValue(columnName)
                        ? String(row.getValue(columnName))
                        : '')}
                  </p>
                  <CopyButton
                    text={
                      cellsNames[String(row.getValue(columnName))] ??
                      (row.getValue(columnName)
                        ? String(row.getValue(columnName))
                        : '')
                    }
                    className="text-muted-foreground"
                  />
                </div>
              </HoverCardContent>
            </HoverCard>
          </div>
        </>
      ),
    };
  });
}
