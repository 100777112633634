import React, { useEffect, useMemo, useState, useCallback } from 'react';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import {
  type Container,
  type ISourceOptions,
  MoveDirection,
  OutMode,
} from '@tsparticles/engine';
import { loadSlim } from '@tsparticles/slim';
import { DEFAULT_COLORS } from '@/colors';

const variantConfigs = {
  default: {
    particleColor: DEFAULT_COLORS.pormade,
    linkColor: DEFAULT_COLORS['pormade-hover'],
  },
  error: {
    particleColor: DEFAULT_COLORS['pormade-red'],
    linkColor: DEFAULT_COLORS['pormade-red-hover'],
  },
  warn: {
    particleColor: DEFAULT_COLORS['pormade-warn'],
    linkColor: DEFAULT_COLORS['pormade-warn-hover'],
  },
};

type BackgroundProps = {
  variant?: keyof typeof variantConfigs;
};

const Background = ({ variant = 'default' }: BackgroundProps) => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = useCallback(
    async (container?: Container): Promise<void> => {
      container;
    },
    [],
  );

  const options: ISourceOptions = useMemo(
    () => ({
      fpsLimit: 120,
      pauseOnOutsideViewport: true,

      interactivity: {
        events: {
          onClick: {
            enable: true,
            mode: 'push',
          },
          onHover: {
            parallax: {
              enable: true,
              smooth: 800,
            },
          },
        },
        modes: {
          push: {
            quantity: 2,
          },
        },
      },
      particles: {
        color: {
          value: variantConfigs[variant].particleColor,
        },
        links: {
          color: variantConfigs[variant].linkColor,
          distance: 150,
          enable: true,
          opacity: 0.7,
          width: 2,
        },
        limit: 1,
        move: {
          direction: MoveDirection.none,
          enable: true,
          outModes: {
            default: OutMode.out,
          },
          random: false,
          speed: 2,
          straight: false,
        },
        number: {
          density: {
            enable: true,
          },
          value: 100,
          limit: {
            mode: 'delete',
            value: 150,
          },
        },
        opacity: {
          value: 0.5,
        },
        shape: {
          type: 'circle',
        },
        size: {
          value: { min: 1, max: 5 },
        },
      },
      detectRetina: true,
    }),
    [variant],
  );

  if (init) {
    return (
      <Particles
        id="tsparticles"
        particlesLoaded={particlesLoaded}
        options={options}
        className="absolute -z-10"
      />
    );
  }

  return <></>;
};

export default React.memo(Background);
