import {
  ColumnFiltersState,
  ColumnSort,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  ChevronLeft,
  ChevronRight,
  ChevronRightIcon,
  ChevronsLeft,
  ChevronsRight,
  Columns3,
  SearchX,
} from 'lucide-react';

import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { cn } from '@/lib/utils';
import { useEffect, useRef, useState } from 'react';
import TrashButton from '../TrashButton';

import { useAuth } from '@/contexts/auth.context';
import { processSheetData } from '@/utils/sheet.utils';
import { useSearchParams } from 'react-router-dom';
import {
  headersNames,
  hiddenColumns,
} from '../../pages/Sheets/configs/sheet.config';
import SearchInput from '../SearchInput';
import { Dialog } from '../ui/dialog';
import { Input } from '../ui/input';
import { ScrollArea } from '../ui/scroll-area';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import { Columns } from './Columns';
import LeadDialog from './LeadDialog';
import { StatusColumn } from './StatusColumn';
import { TLeadObject } from './types';

type DataTableProps = {
  data: TLeadObject[];
  sort?: boolean;
  defaultSort?: ColumnSort;
  headerElement?: (index: number) => React.ReactNode;

  initialPageSize?: number;
  pageSizeOptions?: number[];

  enablePagination?: boolean;

  showTotal?: boolean;

  showCheckAll?: boolean;
  isAbleToCheckAll?: boolean;
  isAllChecked?: boolean;
  handleCheckAll: () => Promise<unknown>;
  handleCheck?: (lead: TLeadObject) => Promise<unknown>;

  outline?: boolean;

  filterInput?: boolean;
  filterColumns?: boolean;

  onRemove?: () => void;
};

export function LeadsDataTable({
  data,
  filterInput = false,
  filterColumns = false,
  sort = false,
  defaultSort,
  initialPageSize = 5,
  outline = false,
  showTotal = true,
  enablePagination = true,
  headerElement,
  onRemove,
  handleCheck,
  isAbleToCheckAll,
  handleCheckAll,
  showCheckAll,
  isAllChecked,
  pageSizeOptions = [initialPageSize, 10, 15, 20],
}: DataTableProps) {
  const processedData = processSheetData(data);
  const headers = Object?.keys(processedData[0]);
  const tablePageSizeOptions = pageSizeOptions.includes(initialPageSize)
    ? pageSizeOptions
    : [...pageSizeOptions, initialPageSize].sort((a, b) => a - b);

  const [searchParams, setSearchParams] = useSearchParams();

  const initialColumnVisibility = () => {
    const transformedRows: { [key: string]: boolean } = {};
    headers.forEach((column: string) => {
      if (hiddenColumns.includes(column)) {
        transformedRows[column] = false;
      } else {
        transformedRows[column] = true;
      }
    });

    return transformedRows;
  };

  const { profile } = useAuth();

  const [sorting, setSorting] = useState<SortingState>(() => {
    const sortingId = searchParams.get('ordenar');
    const sortingDesc = searchParams.get('desc');

    if (sortingId && sortingDesc) {
      if (headers.includes(sortingId)) {
        return [{ id: sortingId, desc: sortingDesc == 'true' }];
      }
    }

    return defaultSort ? [defaultSort] : [];
  });

  const isFirstLoad = useRef<boolean>(true);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState(() => {
    return searchParams.get('busca') ?? '';
  });
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    initialColumnVisibility(),
  );
  const [isOpenLeadDialog, setIsOpenLeadDialog] = useState<boolean>(false);
  const [dialogLeadId, setDialogLeadId] = useState<number>();

  const [pagination, setPagination] = useState(() => {
    const pageIndex = (() => {
      const pageIndex = searchParams.get('pagina')
        ? Number(searchParams.get('pagina')) - 1
        : 0;

      if (pageIndex < 0) {
        return 0;
      }
      return pageIndex;
    })();

    const rowsParm = searchParams.get('linhas');

    const pageSize = (() => {
      if (rowsParm) {
        if (!tablePageSizeOptions.includes(Number(rowsParm))) {
          return initialPageSize;
        }

        return Number(rowsParm);
      }

      return initialPageSize;
    })();

    return {
      pageSize,
      pageIndex,
    };
  });

  const checkedRowsCount = processedData.reduce(
    (previous, current) => (current.tratado ? previous + 1 : previous),
    0,
  );

  const currentDialogLead = processedData.find((lead) => {
    if (lead.id_lead) {
      return lead.id_lead == dialogLeadId;
    }
    if (lead.id_captura_leads) {
      return lead.id_captura_leads == dialogLeadId;
    }
  });

  function openDialog(lead: TLeadObject) {
    setIsOpenLeadDialog(true);
    if (lead.id_lead) {
      setDialogLeadId(Number(lead.id_lead));
      return;
    }
    if (lead.id_captura_leads) {
      setDialogLeadId(Number(lead.id_captura_leads));
    }
  }

  function handleCheckedChange(lead?: TLeadObject) {
    if (handleCheck && lead) {
      return handleCheck(lead);
    }
    return new Promise((resolve) => resolve(true));
  }

  const isAbleToChangeStatus = (lead: TLeadObject) =>
    lead.tratado_usuario ? lead.tratado_usuario == profile?.user : true;

  const statusColumn = StatusColumn({
    handleCheckedChange,
    isAbleToChangeStatus,
    isAbleToCheckAll,
    handleCheckAll,
    showCheckAll,
    isAllChecked,
  });

  const baseColumns = Columns({
    headers,
    globalFilter,
    sort,
    headerElement,
  });

  const columns = handleCheck ? [statusColumn, ...baseColumns] : baseColumns;

  const visibleColumns = Object.entries(columnVisibility).map(
    ([key, value]) => {
      if (value) {
        return key;
      }
    },
  );

  const table = useReactTable({
    data: processedData,
    columns: columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: (columnsVisibility) => {
      const oldGlobalFilter = globalFilter;
      setGlobalFilter('');
      setTimeout(() => {
        setGlobalFilter(oldGlobalFilter);
      }, 0);
      setColumnVisibility(columnsVisibility);
    },

    enableRowSelection: true,
    autoResetPageIndex: false,

    onPaginationChange: setPagination,
    initialState: {
      pagination,
    },
    globalFilterFn: (row, columnIds, filterValue) => {
      if (!visibleColumns.includes(columnIds.toLowerCase())) return false;
      const search = filterValue.toLowerCase();
      const value = String(row.getValue<string>(columnIds));
      return value?.toLowerCase().includes(search);
    },

    state: {
      sorting,
      columnFilters,
      columnVisibility,
      globalFilter,
      pagination,
    },
  });

  function handleDigitPage(value: string) {
    if (value.match(/[^0-9]/)) {
      return;
    }
    const page = Number(value.replace(/[^0-9]/g, '')) ?? 0;

    if (page > table.getPageCount() - 1) {
      if (pagination.pageIndex != table.getPageCount() - 1) {
        const pageIndex = table.getPageCount() - 1;
        setPagination((prev) => ({
          ...prev,
          pageIndex,
        }));
      }
    } else {
      const pageIndex = page - 1 > -2 ? page - 1 : 0;

      setPagination((prev) => ({
        ...prev,
        pageIndex,
      }));
    }
  }

  useEffect(() => {
    searchParams.set('pagina', (pagination.pageIndex + 1).toString());
    setSearchParams(searchParams);
  }, [pagination.pageIndex]);

  useEffect(() => {
    searchParams.set('linhas', pagination.pageSize.toString());
    setSearchParams(searchParams);

    if (!table.getRowModel().rows.length) {
      table.lastPage();
    }
  }, [pagination.pageSize]);

  useEffect(() => {
    searchParams.set('ordenar', sorting[0].id);
    searchParams.set('desc', String(sorting[0].desc));
    setSearchParams(searchParams);
    if (!isFirstLoad.current) table.firstPage();
  }, [sorting]);

  useEffect(() => {
    if (!isFirstLoad.current) table.firstPage();

    if (!headers.find((header) => sorting.find((sort) => sort.id == header))) {
      if (headers.includes('nome')) {
        setSorting([{ id: 'nome', desc: false }]);
        return;
      }

      setSorting([]);
    }
  }, [data]);

  useEffect(() => {
    isFirstLoad.current = false;
  }, []);

  return (
    <div className="w-full">
      <LeadDialog
        isOpen={isOpenLeadDialog}
        setIsOpen={setIsOpenLeadDialog}
        lead={currentDialogLead}
        checkLead={handleCheckedChange}
        isAbleToChangeStatus={isAbleToChangeStatus}
      />
      <div className="flex flex-col-reverse items-center gap-5 py-4 sm:grid sm:grid-flow-col sm:grid-cols-3">
        {filterInput ? (
          <div className="col-span-2 w-full">
            <SearchInput
              id="dataTableSearchInput"
              title="Busque por dados específicos na planilha"
              placeholder="Buscar por dados..."
              value={globalFilter}
              onChange={(event) => {
                table.firstPage();
                const filter = event.target.value.limitSpaces();
                if (filter) {
                  searchParams.set('busca', filter);
                } else {
                  searchParams.delete('busca');
                }
                setSearchParams(searchParams);
                setGlobalFilter(filter);
              }}
            />
          </div>
        ) : null}
        {filterColumns ? (
          <DropdownMenu>
            <DropdownMenuTrigger asChild className="group col-span-1">
              <Button
                variant="outline"
                className="group ml-auto select-none self-end text-foreground sm:mx-0"
              >
                <Columns3 />
                <p className="mx-2">Colunas</p>
                <ChevronRightIcon className="size-4 transition-transform duration-200 group-data-[state=open]:rotate-90" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <ScrollArea className="h-40">
                {table
                  .getAllColumns()
                  .filter((column) => column.getCanHide())
                  .map((column) => (
                    <DropdownMenuCheckboxItem
                      key={column.id}
                      checked={column.getIsVisible()}
                      onCheckedChange={(value) =>
                        column.toggleVisibility(!!value)
                      }
                      onSelect={(event) => event.preventDefault()}
                    >
                      {headersNames[column.id] ?? column.id}
                    </DropdownMenuCheckboxItem>
                  ))}
              </ScrollArea>
            </DropdownMenuContent>
          </DropdownMenu>
        ) : (
          ''
        )}
        {onRemove ? (
          <div
            className={cn(
              'ml-5',
              !filterColumns && !filterInput && 'flex w-full justify-end',
            )}
          >
            <TrashButton title="Excluir planilha" onRemove={onRemove} />
          </div>
        ) : (
          ''
        )}
      </div>

      <div className="flex gap-2 rounded-md border border-muted-foreground">
        <Table>
          {table.getRowModel().rows?.length ? (
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead
                        key={header.id}
                        className={cn(
                          outline
                            ? 'outline outline-1 outline-soft-gray/50'
                            : '',
                        )}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
          ) : (
            ''
          )}
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <Dialog key={row.id}>
                  <TableRow
                    data-state={
                      handleCheck && row.original.tratado ? 'selected' : ''
                    }
                    className="cursor-pointer"
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        key={cell.id}
                        className={cn(
                          'w-full max-w-72 text-xs sm:text-sm',
                          outline
                            ? 'outline outline-1 outline-soft-gray/20'
                            : '',
                        )}
                        onClick={() =>
                          String(cell.column.id) != 'status' &&
                          openDialog(row.original)
                        }
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </Dialog>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={baseColumns.length}
                  className="medium-text h-44 text-center"
                >
                  <div className="flex items-center justify-center gap-2 text-muted-foreground">
                    <SearchX /> Busca sem resultados
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex flex-wrap-reverse items-end justify-center gap-4 space-x-2 truncate py-2">
        <div className="text-md mt-1 flex-1 text-muted-foreground">
          {showTotal ? (
            <>
              <b>Total:</b> {table.getRowCount()}
            </>
          ) : (
            ''
          )}

          {handleCheck ? (
            <>
              <b className="ml-5 text-pormade">Assumidos:</b> {checkedRowsCount}
            </>
          ) : (
            ''
          )}
        </div>

        <Select
          onValueChange={(value) =>
            setPagination((old) => ({ ...old, pageSize: Number(value) }))
          }
          value={String(pagination.pageSize)}
        >
          <SelectTrigger className="mt-2 w-full border border-muted-foreground focus:ring-0 focus:ring-offset-0 sm:w-fit">
            <SelectValue placeholder="Leads p/ pag." />
          </SelectTrigger>
          <SelectContent>
            {tablePageSizeOptions
              .sort((a, b) => a - b)
              .map((number) => (
                <SelectItem
                  key={`PaginationOption-${number}`}
                  value={String(number)}
                >
                  {number}
                </SelectItem>
              ))}
          </SelectContent>
        </Select>

        {enablePagination && table.getPageCount() > 1 ? (
          <>
            <div className="flex items-center gap-2 py-3 text-muted-foreground">
              <Button
                title="Primeira página"
                variant="outline"
                className="select-none"
                size="sm"
                onClick={() => table.firstPage()}
                disabled={!table.getCanPreviousPage()}
              >
                <ChevronsLeft />
              </Button>
              <Button
                title="Página anterior"
                variant="outline"
                className="select-none"
                size="sm"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                <ChevronLeft />
              </Button>
              <form
                className="mx-4 flex items-center gap-2"
                onSubmit={(e) => e.preventDefault()}
              >
                <Input
                  className="h-5/6 w-16 bg-pormade-hover/5 text-center font-semibold text-pormade ring-pormade dark:bg-pormade-hover/5"
                  value={pagination.pageIndex + 1}
                  onChangeValue={handleDigitPage}
                  min={0}
                />
                <p>de {table.getPageCount()}</p>
              </form>
              <Button
                title="Próxima página"
                variant="outline"
                className="select-none"
                size="sm"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                <ChevronRight />
              </Button>
              <Button
                title="Última página"
                variant="outline"
                className="select-none"
                size="sm"
                onClick={() => table.lastPage()}
                disabled={!table.getCanNextPage()}
              >
                <ChevronsRight />
              </Button>
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
