import { useEffect, useRef, useState } from 'react';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import Charts from '@/components/Charts';
import DefaultPage from '@/components/DefaultPage';
import DownloadSheetButton from '@/components/DownloadSheetButton';
import { LeadsDataTable } from '@/components/LeadsDataTable';
import { TLeadObject } from '@/components/LeadsDataTable/types';
import Subtitle from '@/components/Subtitle';
import Title from '@/components/Title';
import myToast from '@/components/Toast';
import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import { useAuth } from '@/contexts/auth.context';
import { useDialog } from '@/hooks/useDialog';
import useLoading from '@/hooks/useLoading';
import { cn } from '@/lib/utils';
import { tabsNames } from '@/pages/Sheets/configs/sheet.config';
import { api } from '@/services/api/api';
import {
  TCrmOriginDetailsResponse,
  TOriginDetail,
} from '@/services/api/api.responses';
import { downloadSheet, getAxiosErrorMessage } from '@/services/api/api.utils';
import { socket } from '@/services/socket';
import { TChartInfo } from '@/types';
import { TLead } from '@/types/leads';
import { ColumnSort } from '@tanstack/react-table';
import { AxiosError } from 'axios';
import {
  Cog,
  Download,
  Loader2,
  Trash,
  TrendingUp,
  UserPlus,
} from 'lucide-react';
import { toast } from 'react-toastify';
import SelectTab from './SelectTab';

type TLeadTab = {
  segmento_setor: string;
  lead: TLead;
};

type LeadsTab = {
  tab: string;
  leads: TLead[];
};

type CheckedTab = {
  segmento_setor: string;
  leads_tratados: TLead[];
};

const defaultInitialTab = 'Geral';

export default function OriginLeads() {
  const dialog = useDialog();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const initialTab = useRef<string>();

  const { id: originId } = useParams();
  const { hasRequiredPermissions } = useAuth();

  const [origin, setOrigin] = useState<TOriginDetail>({} as TOriginDetail);
  const [currentLeads, setCurrentLeads] = useState<TLead[]>();
  const [selectedTab, setSelectedTab] = useState<string>(() => {
    const tabSearchParam = searchParams.get('aba');
    initialTab.current = tabSearchParam ?? defaultInitialTab;
    return initialTab.current;
  });
  const [resultsInfos, setResultsInfos] = useState<TChartInfo[]>();

  const [isLoadingData, startLoadData] = useLoading(true);
  const [isLoadingCharts, startLoadCharts] = useLoading(true);
  const [isDownloading, startDownload] = useLoading(false);

  const isLoadingDataRef = useRef<boolean>(true);
  const tabsLeads = useRef<LeadsTab[]>([]);
  const isCheckingAllLeads = useRef<boolean>(false);

  const currentLeadsKeys = currentLeads && Object.keys(currentLeads[0]);
  const tableDefaultSort: ColumnSort =
    currentLeadsKeys && currentLeadsKeys.includes('data_coleta')
      ? { id: 'data_coleta', desc: true }
      : { id: 'nome', desc: false };

  const chartData = origin?.leads_graficos?.sort(
    (a, b) => b.quantidade - a.quantidade,
  );

  function createLeadsTab(tab: string, leads: TLead[]) {
    if (!tabsLeads.current.find((tabGroup) => tabGroup.tab == tab)) {
      tabsLeads.current = [...tabsLeads.current, { tab, leads }];
    }
  }

  const isAllLeadsAssumed = currentLeads?.every((value) => value.tratado);

  async function handleCheckLead(lead: TLeadObject) {
    return api
      .patch('/leads/tratar', {
        id_lead: lead.id_lead,
      })
      .catch((error) =>
        myToast.error(
          getAxiosErrorMessage(
            error,
            'Não foi possível alterar o status do lead.',
          ),
        ),
      );
  }

  async function checkAllLeads() {
    if (!isCheckingAllLeads.current) {
      if (selectedTab.toLowerCase() == 'pré-vendas') {
        isCheckingAllLeads.current = true;
        await api
          .patch('leads/tratar/segmento_setor', {
            crm_origem_id: originId,
            segmento_setor: selectedTab,
          })
          .finally(() => {
            setTimeout(() => {
              isCheckingAllLeads.current = false;
            }, 20);
          })
          .catch((error) => {
            myToast.error(
              getAxiosErrorMessage(
                error,
                'Não foi possível alterar o status dos leads',
              ),
            );
          });
      } else {
        myToast.warn('Você não pode assumir todos os leads dessa aba');
      }
    }
  }

  async function handleDownloadSheet(includeAssumed: boolean) {
    if (isAllLeadsAssumed && !includeAssumed) {
      myToast.warn('Não há leads que não foram assumidos.');
      return;
    }

    await startDownload(() =>
      downloadSheet({ origin, tab: selectedTab, includeAssumed }),
    );
  }

  function handleDeleteLeads() {
    dialog
      .open({
        type: 'warning',
        title: 'Confirmação de exclusão',
        text: (
          <p className="mt-4 text-base text-muted-foreground">
            Tem certeza que deseja excluir
            <b className="text-pormade-red"> TODOS</b> os leads? Essa ação é
            permanente e{' '}
            <b className="text-pormade-red">não será possível recuperá-los</b>
          </p>
        ),
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar',
      })
      .then((result) => {
        if (result) {
          if (result.isConfirmed) {
            deleteLeads();
          }
        }
      });
  }

  function deleteLeads() {
    const loaderToastId = myToast.info('Excluindo leads', {
      autoClose: false,
      icon: <Loader2 className="animate-spin" />,
      closeButton: false,
    });

    api
      .delete('leads/remover', {
        params: { crm_origem_id: origin.crm_origem_id },
      })
      .then(() => {
        myToast.info(
          `Leads da origem *${origin.crm_origem_nome}* excluídos com sucesso`,
        );
        navigate('/leads');
      })
      .catch((error) =>
        myToast.error(
          getAxiosErrorMessage(error, 'Não foi possível excluir os leads'),
        ),
      )
      .finally(() => toast.dismiss(loaderToastId));
  }

  function insertTabLead(tab: string, lead: TLead) {
    const findedTabIndex = tabsLeads.current.findIndex(
      (tabGroup) => tabGroup.tab == tab,
    );

    const findedTab = tabsLeads.current[findedTabIndex];

    tabsLeads.current[findedTabIndex] = {
      ...findedTab,
      leads: [...findedTab.leads, lead],
    };
  }

  async function loadAllTabs(tabs: string[]) {
    if (initialTab.current) {
      if (tabs.includes(initialTab.current)) {
        setSelectedTab(initialTab.current);
      } else {
        initialTab.current = defaultInitialTab;
        setSelectedTab(defaultInitialTab);
      }
    }

    await startLoadData(
      async () =>
        await Promise.all(
          tabs?.map(
            async (tab) =>
              await api
                .get<TLead[]>('leads', {
                  params: { crm_origem_id: originId, segmento_setor: tab },
                })
                .then((response) => createLeadsTab(tab, response.data)),
          ),
        )
          .catch((error) => {
            if (error.response?.status == 404)
              myToast.error('Leads não encontrados.');
            else
              myToast.error(
                getAxiosErrorMessage(
                  error,
                  `Ocorreu um erro ao obter os leads da feira ${origin?.crm_origem_nome}`,
                ),
              );
          })
          .finally(() => {
            setCurrentLeads(
              tabsLeads.current.find(
                (tabLead) => tabLead.tab == initialTab.current,
              )?.leads,
            );
            isLoadingDataRef.current = false;
          }),
    );
  }

  async function getResultsInfos() {
    await api
      .get<TCrmOriginDetailsResponse>('/leads/prospeccoes/resultados', {
        params: { crm_origem_id: originId },
      })
      .then(({ data }) => {
        const formattedInfos: TChartInfo[] = [];

        Object.entries(data.dados).forEach(([key, value]) => {
          const name = key[0].toUpperCase() + key.slice(1, key.length);
          formattedInfos.push({ name, value });
        });

        setResultsInfos(formattedInfos);
      });
  }

  async function getOriginDetails() {
    if (originId) {
      isLoadingDataRef.current = true;
      startLoadCharts(async () => {
        await api
          .get<TOriginDetail>('leads/origens', {
            params: { crm_origem_id: originId },
          })
          .then(async (response) => {
            setOrigin(response.data);
            const data = response.data;
            const tabs = data.segmentos_setores;

            await loadAllTabs(tabs);
          })
          .catch((error: AxiosError) => {
            if (error.response?.status == 404) {
              myToast.error('Origem não encontrada.');
            } else {
              myToast.error(
                getAxiosErrorMessage(
                  error,
                  'Não foi possível obter detalhes da origem.',
                ),
              );
            }
            navigate('/leads');
          });

        await getResultsInfos();
      });
    } else {
      myToast.error('Origem não encontrada');
      navigate('/leads');
    }
  }

  useEffect(() => {
    searchParams.set('aba', selectedTab);
    setSearchParams(searchParams);

    (async () => {
      if (originId && !isLoadingDataRef.current) {
        const findedLeadsTabIndex = tabsLeads.current.findIndex(
          (leadTab) => leadTab.tab == selectedTab,
        );
        if (findedLeadsTabIndex == -1) {
          await api
            .get<TLead[]>('leads', {
              params: { crm_origem_id: originId, segmento_setor: selectedTab },
            })
            .then(({ data }) => {
              createLeadsTab(selectedTab, data);
              setCurrentLeads(data);
            })
            .catch((error: AxiosError) => {
              myToast.error(
                getAxiosErrorMessage(error, 'Falha ao carregar leads.'),
              );
            });
        } else {
          setCurrentLeads(tabsLeads.current[findedLeadsTabIndex].leads);
        }
      }
    })();
  }, [selectedTab]);

  useEffect(() => {
    function handleNewOrigin(tab: string) {
      if (!origin.segmentos_setores.includes(tab)) {
        setOrigin((oldOrigin) => {
          if (!oldOrigin.segmentos_setores) {
            oldOrigin.segmentos_setores = [];
          }

          if (!oldOrigin.segmentos_setores.includes(tab)) {
            return {
              ...oldOrigin,
              segmentos_setores: [...oldOrigin.segmentos_setores, tab].sort(),
            };
          }
          return oldOrigin;
        });
      }
    }

    function handleNewLead({ lead, segmento_setor }: TLeadTab) {
      handleNewOrigin(segmento_setor);

      if (!tabsLeads.current.find((tabLead) => tabLead.tab == segmento_setor)) {
        createLeadsTab(segmento_setor, [lead]);
      } else {
        insertTabLead(segmento_setor, lead);
      }

      insertTabLead('Geral', lead);

      if (segmento_setor === selectedTab || selectedTab == 'Geral') {
        setCurrentLeads((oldLeads) =>
          oldLeads ? [...oldLeads, lead] : [lead],
        );
      }
    }

    function handleCheckedLead({ lead, segmento_setor }: TLeadTab) {
      handleNewOrigin(segmento_setor);
      const findedTabIndex = tabsLeads.current.findIndex(
        (tab) => tab.tab == segmento_setor,
      );
      const findedGeralIndex = tabsLeads.current.findIndex(
        (tab) => tab.tab == 'Geral',
      );

      if (findedTabIndex > -1) {
        const findedLeadIndex = tabsLeads.current[
          findedTabIndex
        ].leads.findIndex((tabLead) => tabLead.id_lead == lead.id_lead);

        tabsLeads.current[findedTabIndex].leads[findedLeadIndex] = lead;
      }

      if (findedGeralIndex > -1) {
        const findedLeadIndex = tabsLeads.current[
          findedGeralIndex
        ].leads.findIndex((tabLead) => tabLead.id_lead == lead.id_lead);

        tabsLeads.current[findedGeralIndex].leads[findedLeadIndex] = lead;
      }

      if (segmento_setor == selectedTab || selectedTab == 'Geral') {
        setCurrentLeads((oldLeads) =>
          oldLeads
            ? [
                ...oldLeads.filter(
                  (oldLead) => oldLead.id_lead != lead.id_lead,
                ),
                lead,
              ]
            : [lead],
        );
      }
    }

    function handleCheckAllLeads(checkedTab: CheckedTab) {
      const leadsIds = checkedTab.leads_tratados.map((lead) => lead.id_lead);
      const status = checkedTab.leads_tratados[0].tratado;
      const user = checkedTab.leads_tratados[0].tratado_usuario;

      if (
        selectedTab.toLowerCase() == checkedTab.segmento_setor.toLowerCase()
      ) {
        setCurrentLeads((old) =>
          old?.map((lead) => {
            if (leadsIds.includes(lead.id_lead)) {
              return {
                ...lead,
                tratado: status,
                tratado_usuario: user,
              };
            }

            return lead;
          }),
        );
      }

      if (selectedTab.toLowerCase() == 'geral') {
        setCurrentLeads((old) =>
          old?.map((lead) => {
            if (
              lead.segmento_setor.toLowerCase() ==
              checkedTab.segmento_setor.toLowerCase()
            ) {
              return {
                ...lead,
                tratado: status,
                tratado_usuario: user,
              };
            } else {
              return lead;
            }
          }),
        );
      }

      const findedTargetTabLeads = tabsLeads.current.find(
        (tabLeads) =>
          tabLeads.tab.toLowerCase() == checkedTab.segmento_setor.toLowerCase(),
      );

      const findedGeralTabLeads = tabsLeads.current.find(
        (tabLeads) => tabLeads.tab.toLowerCase() == 'geral',
      );

      if (findedTargetTabLeads && findedGeralTabLeads) {
        tabsLeads.current = [
          ...tabsLeads.current.filter(
            (old) =>
              old.tab.toLowerCase() !=
                checkedTab.segmento_setor.toLowerCase() &&
              old.tab.toLowerCase() != 'geral',
          ),
          {
            tab: findedGeralTabLeads.tab,
            leads: findedGeralTabLeads.leads.map((lead) => {
              if (
                lead.segmento_setor.toLowerCase() ==
                  checkedTab.segmento_setor.toLowerCase() &&
                leadsIds.includes(lead.id_lead)
              ) {
                return {
                  ...lead,
                  tratado: status,
                  tratado_usuario: user,
                };
              } else return lead;
            }),
          },
          {
            tab: checkedTab.segmento_setor,
            leads: findedTargetTabLeads.leads.map((lead) => {
              if (leadsIds.includes(lead.id_lead)) {
                return {
                  ...lead,
                  tratado: status,
                  tratado_usuario: user,
                };
              }

              return lead;
            }),
          },
        ];
      }
    }

    if (origin.crm_origem_id) {
      socket.on(
        `segmento_tratado?crm_origem_id=${originId}`,
        handleCheckAllLeads,
      );
      socket.on(`lead_info?crm_origem_id=${originId}`, handleNewLead);
      socket.on(
        `lead_info_tratado?crm_origem_id=${originId}`,
        handleCheckedLead,
      );
    }

    return () => {
      socket.off(`segmento_tratado?crm_origem_id=${originId}`);
      socket.off(`lead_info?crm_origem_id=${originId}`);
      socket.off(`lead_info_tratado?crm_origem_id=${originId}`);
    };
  }, [origin, selectedTab]);

  useEffect(() => {
    if (!isLoadingDataRef.current) {
      getOriginDetails();
    }
  }, [originId]);

  useEffect(() => {
    getOriginDetails();

    return () => {
      socket.off(`segmento_tratado?crm_origem_id=${originId}`);
      socket.off(`lead_info?crm_origem_id=${originId}`);
      socket.off(`lead_info_tratado?crm_origem_id=${originId}`);
    };
  }, []);

  return (
    <DefaultPage
      resetScrollOnNavigate
      key={originId}
      className="mx-auto max-w-[85rem] pb-32"
      goBackTo="/leads"
      pageDescription={{
        title: 'LEADS DA FEIRA',
        description: (
          <p>
            Leads capturados durante a feira{' '}
            <strong>"{origin.crm_origem_nome}"</strong>
          </p>
        ),
      }}
    >
      <header>
        <Title marginBottom className="text-center">
          <div>
            <p className="scale-75">Leads da feira</p>
            {!!(origin.crm_origem_id && origin.crm_origem_nome) ? (
              <p className="underline decoration-pormade underline-offset-4">
                {origin.crm_origem_nome}
              </p>
            ) : (
              <Skeleton className="h-[3rem] w-full" />
            )}
          </div>
        </Title>
        <Subtitle className="text-center text-muted-foreground">
          Veja todos os leads da feira
        </Subtitle>
      </header>

      <div className="mt-16 w-full justify-start gap-5">
        <SelectTab
          tabs={origin.segmentos_setores}
          onSelect={setSelectedTab}
          selectedTab={selectedTab}
          disabled={isLoadingData}
        />
      </div>
      <div className="mt-5 flex w-full justify-end">
        {hasRequiredPermissions(['excluir_planilhas']) ? (
          <Button
            variant="destructive"
            title="Excluir leads"
            className="gap-2"
            onClick={handleDeleteLeads}
          >
            <Trash className="transition-colors duration-200" />
            <p>Excluir</p>
          </Button>
        ) : (
          ''
        )}
      </div>
      {currentLeads && currentLeads.length ? (
        <>
          <div
            className={cn(
              isLoadingData && 'opacity-50',
              'w-full transition-opacity',
            )}
          >
            <LeadsDataTable
              filterColumns
              filterInput
              sort
              defaultSort={tableDefaultSort}
              outline
              initialPageSize={7}
              data={currentLeads}
              handleCheck={handleCheckLead}
              isAllChecked={isAllLeadsAssumed}
              isAbleToCheckAll={true}
              showCheckAll={selectedTab.toLowerCase() == 'pré-vendas'}
              handleCheckAll={checkAllLeads}
            />
          </div>
          <div className="my-4 flex flex-col gap-4 sm:flex-row sm:self-start">
            {hasRequiredPermissions(['baixar_planilhas']) ? (
              <DownloadSheetButton
                downloadText={`Baixar ${tabsNames[selectedTab] ?? selectedTab}`}
                loadingText="Baixando planilha"
                request={handleDownloadSheet}
                disabled={isDownloading}
              >
                <Button variant={'outline'} className="gap-2">
                  {isDownloading ? (
                    <>
                      <Loader2 className="animate-spin" />
                      Baixando planilha
                    </>
                  ) : (
                    <>
                      <Download />
                      Baixar {tabsNames[selectedTab] ?? selectedTab}
                    </>
                  )}
                </Button>
              </DownloadSheetButton>
            ) : (
              ''
            )}
            {hasRequiredPermissions(['cadastrar_leads']) ? (
              <Link to={`/leads/cadastrar/${originId}`}>
                <Button className="flex gap-2">
                  <UserPlus /> Cadastrar Lead
                </Button>
              </Link>
            ) : (
              ''
            )}
          </div>
        </>
      ) : (
        <div className="mb-20 mt-5 w-full">
          <Skeleton className="h-10 w-full" />
          <Skeleton className="mt-5 h-[24rem] w-full" />
        </div>
      )}
      {isLoadingCharts ? (
        <Skeleton className="my-8 h-96 w-full" />
      ) : (
        <div
          data-two-charts={!!(resultsInfos && chartData)}
          className="my-8 grid w-full grid-cols-1 gap-10 data-[two-charts=true]:md:grid-cols-2"
        >
          {resultsInfos ? (
            <Charts
              nameKey="name"
              valueKey="value"
              data={resultsInfos.sort(
                (a, b) => Number(b.value) - Number(a.value),
              )}
            >
              <Charts.Title className="mb-8">
                <TrendingUp size={28} /> Prospecções
              </Charts.Title>
            </Charts>
          ) : null}
          {chartData ? (
            <Charts
              nameKey="nome"
              valueKey="quantidade"
              data={chartData.filter((data) => data.quantidade > 0)}
            >
              <Charts.Title className="mb-8">
                <Cog size={28} /> Processamento
              </Charts.Title>
            </Charts>
          ) : null}
        </div>
      )}
    </DefaultPage>
  );
}
