import { read, utils } from 'xlsx';
import { tabsNames } from '@/pages/Sheets/configs/sheet.config';
import dayjs from 'dayjs';

export function sheetFileToJson(
  sheetFile: File,
  sheeetRows?: number,
): Promise<object[] | null> {
  const reader = new FileReader();
  reader.readAsArrayBuffer(sheetFile);

  return new Promise((resolve) => {
    reader.onload = (e) => {
      const data = e?.target?.result;
      const workbook = read(data, {
        type: 'array',
        ...(sheeetRows ? { sheetRows: sheeetRows + 1 } : ''),
      });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const jsonData = utils.sheet_to_json(sheet, {
        defval: '---',
        raw: false,
        blankrows: false,
      }) as object[];

      resolve(jsonData);
      return jsonData;
    };

    reader.onerror = () => {
      resolve(null);
    };

    reader.onabort = () => {
      resolve(null);
    };
  });
}

export async function getSheetLength(sheetFile: File): Promise<number | null> {
  const reader = new FileReader();
  reader.readAsArrayBuffer(sheetFile);

  return new Promise((resolve) => {
    reader.onload = (e) => {
      const fileData = e?.target?.result;
      const workbook = read(fileData, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const csvData = utils.sheet_to_csv(sheet, { blankrows: false });
      const lines = csvData.split('\n').length - 1;
      resolve(lines);
    };

    reader.onerror = () => {
      resolve(null);
    };

    reader.onabort = () => {
      resolve(null);
    };
  });
}

export function processSheetData(data: object[]) {
  return data.map((item) => {
    const result: { [key: string]: any } = {};

    Object.entries(item).map(([key, value]) => {
      const processedKey = key.replace(/\./g, '');
      result[processedKey] = value;
    });

    return result;
  });
}

export function getSheetFilename(sheetName: string, tabName: string) {
  return `${sheetName} - ${tabsNames[tabName] ?? tabName} - ${dayjs().format(
    'DD-MM-YYYY-HH-mm-ss',
  )}`.replace('.', '');
}
