import '@/configs/customPrototypes.config';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './contexts/auth.context';
import { DialogProvider } from './hooks/useDialog';

import Header from '@/components/Header';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { useEffect } from 'react';
import { NavigationProvider } from './contexts/navigation.context';
import { ProcessingSheetProvider } from './contexts/processing.context';
import { api } from './services/api/api';
import { socket } from './services/socket';

dayjs.locale('pt-br');

export const defaultToastClassName =
  'outline outline-1 outline-white backdrop-blur-md bg-white/80 dark:bg-dark-gray/20';

function App() {
  useEffect(() => {
    socket.on('connect', () => {
      api.interceptors.request.use((config) => {
        config.headers['room'] = socket.id;
        return config;
      });
    });
  }, []);

  return (
    <AuthProvider>
      <NavigationProvider>
        <ProcessingSheetProvider>
          <DialogProvider>
            <ToastContainer
              position="bottom-right"
              limit={5}
              theme="dark"
              draggablePercent={60}
              style={{ marginTop: '4.2rem' }}
              toastClassName={defaultToastClassName}
            />
            <>
              <Header />
              <Outlet />
            </>
          </DialogProvider>
        </ProcessingSheetProvider>
      </NavigationProvider>
    </AuthProvider>
  );
}

export default App;
