import { cn } from '@/lib/utils';
import { getStartAndEndOfMonth } from '@/utils/date.utils';
import { PopoverClose } from '@radix-ui/react-popover';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { CalendarIcon } from 'lucide-react';
import { useEffect } from 'react';
import { DateRange } from 'react-day-picker';

import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import dayjs from 'dayjs';

const { startOfMonth, endOfMonth } = getStartAndEndOfMonth();

type DatePickerRangeProps = {
  className?: string;
  date?: DateRange;
  setDate: (date?: DateRange) => void;
  initialCurrentMounthRange?: boolean;
  currentMounthButton?: boolean;
  onSelect?: (date?: DateRange) => void;
  onConfirm?: (date?: DateRange) => void;
  numberOfMonths?: number;
  disabled?: boolean;
};

export function DatePickerRange({
  date,
  className,
  onSelect,
  onConfirm,
  setDate,
  numberOfMonths = 1,
  initialCurrentMounthRange,
  currentMounthButton,
  disabled,
}: DatePickerRangeProps) {
  function handleOnConfirm(isOpen: boolean) {
    if (!isOpen && onConfirm) onConfirm(date);
  }
  function setCurrentMounthDateRange() {
    if (
      dayjs(date?.from).toString() != dayjs(startOfMonth).toString() ||
      dayjs(date?.to).toString() != dayjs(endOfMonth).toString()
    )
      setDate({
        from: dayjs(startOfMonth).toDate(),
        to: dayjs(endOfMonth).toDate(),
      });
    else setDate();
  }

  useEffect(() => {
    if (initialCurrentMounthRange) {
      if (!date || (date && !date.from && !date.to)) {
        setCurrentMounthDateRange();
      }
    }
  }, []);

  return (
    <div
      className={cn(
        'flex flex-wrap items-center justify-center gap-2',
        className,
      )}
    >
      <Popover onOpenChange={handleOnConfirm} modal={true}>
        <PopoverTrigger asChild>
          <Button
            disabled={disabled}
            id="date"
            variant={'outline'}
            className={cn(
              'justify-center text-left text-base font-normal',
              !date && 'text-muted-foreground',
            )}
          >
            <CalendarIcon className="mr-2 size-5" />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, 'dd LLL. y', { locale: ptBR })} -{' '}
                  {format(date.to, 'dd LLL. y', { locale: ptBR })}
                </>
              ) : (
                format(date.from, 'dd LLL. y', { locale: ptBR })
              )
            ) : (
              <span>Selecione um período</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="z-0 grid w-auto p-0"
          avoidCollisions={false}
          align="center"
          side="bottom"
        >
          <Calendar
            disabled={disabled}
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={onSelect}
            toggleCurrentMonth={
              currentMounthButton ? setCurrentMounthDateRange : undefined
            }
            numberOfMonths={numberOfMonths}
            fixedWeeks
          />

          {onConfirm ? (
            <PopoverClose asChild>
              <Button className="mx-10 my-5" variant={'outline'}>
                Confirmar
              </Button>
            </PopoverClose>
          ) : null}
        </PopoverContent>
      </Popover>
    </div>
  );
}
