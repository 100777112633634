import DefaultPage from '@/components/DefaultPage';
import { Skeleton } from '@/components/ui/skeleton';
import { useEffect, useReducer } from 'react';

export default function LoadingPage() {
  const [isLoaderOn, toggleIsLoaderOn] = useReducer((state) => !state, false);

  useEffect(() => {
    const loaderTimeout = setTimeout(() => {
      toggleIsLoaderOn();
    }, 2000);

    return () => clearTimeout(loaderTimeout);
  }, []);
  return (
    <DefaultPage
      data-hidden={!isLoaderOn}
      className="transition-opacity duration-1000 data-[hidden=false]:opacity-100 data-[hidden=true]:opacity-0"
    >
      <section className="center-center flex size-full w-full max-w-6xl flex-col items-center justify-center">
        <Skeleton className="h-12 w-[90%] md:w-1/2" />
        <Skeleton className="mt-5 h-8 w-1/2 md:w-1/3" />
        <Skeleton className="mt-16 h-80 w-full" />
      </section>
    </DefaultPage>
  );
}
