import useLoading from '@/hooks/useLoading';
import { api } from '@/services/api/api';
import { TLeadInformations } from '@/services/api/api.responses';
import { getAxiosErrorMessage } from '@/services/api/api.utils';
import { User, X } from 'lucide-react';
import { useEffect, useState } from 'react';
import {
  cellsNames,
  headersNames,
} from '../../../pages/Sheets/configs/sheet.config';
import CopyButton from '../../CopyButton';
import Loading from '../../Loading';
import SeparatorTitle from '../../SeparatorTitle';
import myToast from '../../Toast';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '../../ui/dialog';
import { ScrollArea } from '../../ui/scroll-area';
import { Separator } from '../../ui/separator';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../ui/tabs';
import CheckboxStatus from '../CheckboxStatus';
import CRMCard from './CRMCard';
import SiSCard from './SiSCard';
import { TLeadObject } from '../types';

type LeadDialogProps = {
  isOpen?: boolean;
  lead?: TLeadObject;
  setIsOpen: (state: boolean) => void;
  checkLead: (lead: TLeadObject) => Promise<unknown>;
  isAbleToChangeStatus: (lead: TLeadObject) => boolean;
};

const ignoredTabsToRequest = ['novas_oportunidades'];

const notHiddenBasicInfos = ['tratado', 'tratado_usuario'];

export default function LeadDialog({
  lead,
  isOpen,
  setIsOpen,
  checkLead,
  isAbleToChangeStatus,
}: LeadDialogProps) {
  const cellValue = (value: any) =>
    lead && lead[value] !== null
      ? (cellsNames[String(lead[value])] ?? String(lead[value]))
      : null;

  const leadText = lead
    ? `Lead: ${lead['nome']}\n\nInformações:\n${Object.keys(lead)
        .filter(
          (key) => lead.hasOwnProperty(key) && lead[key] && !key.includes('id'),
        )
        .map((key) => ` - ${headersNames[key] || key}: ${cellValue(key)}`)
        .join('\n')}`
    : '';

  const [moreLeadInfos, setMoreLeadInfos] = useState<TLeadInformations>();
  const [isLoadingMoreLeadInfos, startLoadingMoreLeadInfos] = useLoading();

  const ableToCheck = lead && isAbleToChangeStatus(lead);

  useEffect(() => {
    if (lead && isOpen) {
      if (!ignoredTabsToRequest.includes(String(lead.segmento_setor) ?? ''))
        startLoadingMoreLeadInfos(() =>
          api
            .get<TLeadInformations>('/lead/informacoes/sis-crm', {
              params: {
                telefone: lead.telefone ?? '',
                celular: lead.celular ?? '',
                email: lead.email ?? '',
                cnpj: lead.cnpj ?? '',
              },
            })
            .then(({ data }) => setMoreLeadInfos(data))
            .catch((error) =>
              myToast.error(
                getAxiosErrorMessage(
                  error,
                  'Não foi possível obter informações adicionais do lead. Tente novamente mais tarde.',
                ),
              ),
            ),
        );
    }

    if (!isOpen) setMoreLeadInfos(undefined);
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="max-h-[90vh] w-full max-w-4xl border-muted-foreground">
        {lead ? (
          <div className="h-full pb-14">
            <DialogClose className="absolute right-2 top-2 flex justify-end">
              <X size={30} />
            </DialogClose>
            <DialogHeader className="mt-8">
              <DialogTitle>
                <div className="subtitle flex items-center justify-between gap-3">
                  <span className="flex items-center gap-2 text-start underline decoration-pormade underline-offset-4">
                    <User /> {lead['nome']}
                  </span>{' '}
                  <div className="flex items-center gap-2 text-sm">
                    <CopyButton text={leadText} description />
                  </div>
                </div>

                <div
                  data-disabled={!ableToCheck}
                  className="ml-5 mt-5 gap-2 text-muted-foreground"
                >
                  <CheckboxStatus
                    id="checkLeadStatus"
                    label={
                      lead.tratado
                        ? `Assumido por ${lead.tratado_usuario}`
                        : 'Assumir lead'
                    }
                    disabled={!ableToCheck}
                    checked={Boolean(lead.tratado) ?? false}
                    onCheckedChange={() => checkLead(lead)}
                  />
                </div>
              </DialogTitle>
            </DialogHeader>
            {/* <p className="medium-text font-medium mt-5">Informações Gerais:</p> */}
            <div className="custom-scrollbar mt-5 max-h-[60vh] overflow-y-auto px-5 pb-10 lg:max-h-[60vh] 2xl:max-h-[66vh]">
              <div>
                <SeparatorTitle className="my-8">
                  Informações básicas
                </SeparatorTitle>
                <ScrollArea className="h-64 rounded-md px-5">
                  {Object.entries(lead).map(([key, value], index) => {
                    const leadInfo = (
                      <div
                        key={`${key}-${index}`}
                        className="text-sm md:text-base"
                      >
                        <p>
                          {headersNames[key] || key}:{' '}
                          <b className="font-semibold">
                            {cellValue(key) ?? value ?? '-'}
                          </b>
                        </p>
                        <Separator className="my-2" />
                      </div>
                    );
                    if (!key.includes('id')) {
                      if (notHiddenBasicInfos.includes(key)) {
                        return leadInfo;
                      }

                      if (value) return leadInfo;
                    }
                  })}
                </ScrollArea>
              </div>

              <div>
                {isLoadingMoreLeadInfos ? (
                  <SeparatorTitle className="my-8">
                    <Loading orientation="horizontal" iconSize={30}>
                      Buscando informações
                    </Loading>
                  </SeparatorTitle>
                ) : (
                  ''
                )}

                {!isLoadingMoreLeadInfos &&
                (moreLeadInfos?.crm.length || moreLeadInfos?.sis.length) ? (
                  <SeparatorTitle className="my-8">
                    Informações adicionais
                  </SeparatorTitle>
                ) : (
                  ''
                )}

                {!isLoadingMoreLeadInfos ? (
                  moreLeadInfos?.crm.length || moreLeadInfos?.sis.length ? (
                    <Tabs
                      defaultValue={moreLeadInfos?.sis.length ? 'sis' : 'crm'}
                    >
                      <TabsList className="*:medium-text mb-10 grid w-full grid-cols-2 py-0 *:flex *:gap-2 *:outline *:outline-1 *:-outline-offset-1 *:transition-all *:duration-300 *:ease-in-out">
                        <TabsTrigger
                          data-hidden={!moreLeadInfos.sis.length}
                          data-fill-space={!moreLeadInfos.crm.length}
                          className="col-span-1 data-[fill-space=true]:col-span-2 data-[hidden=true]:hidden data-[state=active]:bg-pormade data-[state=active]:text-white"
                          value="sis"
                        >
                          SiS ({moreLeadInfos.sis.length})
                        </TabsTrigger>
                        <TabsTrigger
                          data-hidden={!moreLeadInfos.crm.length}
                          data-fill-space={!moreLeadInfos.sis.length}
                          className="col-span-1 data-[fill-space=true]:col-span-2 data-[hidden=true]:hidden data-[state=active]:bg-pormade data-[state=active]:text-white"
                          value="crm"
                        >
                          Card CRM ({moreLeadInfos.crm.length})
                        </TabsTrigger>
                      </TabsList>

                      {/* <SearchInput placeholder="Buscar..." className="mb-5" /> */}

                      <div>
                        <TabsContent
                          value="sis"
                          data-hidden={!moreLeadInfos.sis.length}
                          className="grid gap-4 data-[hidden=true]:hidden"
                        >
                          {moreLeadInfos.sis.map((sisInfos) => (
                            <SiSCard
                              key={sisInfos.sis_id}
                              sisInfos={sisInfos}
                            />
                          ))}
                        </TabsContent>
                        <TabsContent
                          value="crm"
                          data-hidden={!moreLeadInfos.crm.length}
                          className="grid gap-6 data-[hidden=true]:hidden"
                        >
                          {moreLeadInfos.crm.map((crmInfos) => (
                            <CRMCard
                              key={crmInfos.crm_card}
                              crmInfos={crmInfos}
                            />
                          ))}
                        </TabsContent>
                      </div>
                    </Tabs>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </DialogContent>
    </Dialog>
  );
}
