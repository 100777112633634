import { cn } from '@/lib/utils';

export const sheetNameConfig = {
  maxLength: 60,
};

export const segmentNameConfig = {
  maxLength: 30,
};

export const defaultInputClassName = (errorMessage?: string) =>
  cn(
    errorMessage ? ' ring-pormade-red' : '  border-input  ring-gray',
    'flex h-10 w-full rounded-md border bg-background px-3 focus-visible:ring-1  focus-visible:ring-offset-2 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none  disabled:cursor-not-allowed disabled:opacity-50 transition-all duration-200 ring-1',
  );
