import { cn } from '@/lib/utils';
import { TSelectItem } from '@/types';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  findConfigByValue,
  getInitialHeadersIDs,
  headersComboboxData,
  possibleContactValues,
  possibleJobValues,
} from './configs/sheetHeaders.config';

import { AlertTriangle, Pin } from 'lucide-react';

import { Combobox } from '@/components/Combobox';
import { DataTable } from '@/components/DataTable';
import DefaultPage from '@/components/DefaultPage';
import { Dialog } from '@/components/Dialog';
import Loading from '@/components/Loading';
import Subtitle from '@/components/Subtitle';
import Title from '@/components/Title';
import myToast from '@/components/Toast';
import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import { useNavigation } from '@/contexts/navigation.context';
import { useProcessigSheet } from '@/contexts/processing.context';
import { useDialog } from '@/hooks/useDialog';
import useLoading from '@/hooks/useLoading';
import { api } from '@/services/api/api';
import { TSheetsConfigs } from '@/services/api/api.dto';
import { TCrmOrigin } from '@/services/api/api.responses';
import { getSheetLength, sheetFileToJson } from '@/utils/sheet.utils';
import { TRegisterSheetSchema } from './RegisterSheet/registerSheet.schema';

const sheeetRows = 7;

type THeaderConfig = { idColumn: number; value: string; label: string };
type TSheetConfig = { sheet?: string; config: THeaderConfig[] };
type TSheetLoaded = {
  name: string;
  size: number;
  data: Object[];
  length: number;
};

type TCrmOrigins = {
  sheetOrigin?: TCrmOrigin;
  appOrigin?: TCrmOrigin;
};

export default function ConfigSheet() {
  const location = useLocation();
  const navigate = useNavigate();
  const { processSheet } = useProcessigSheet();

  const comboboxData: TSelectItem[] = [
    { value: '', label: 'Selecione ...' },
    ...headersComboboxData,
  ];

  const localState: TRegisterSheetSchema | null = location?.state;
  const sheetCrmOriginId = localState?.sheet_crm_origin_id;
  const appCrmOriginId = localState?.app_crm_origin_id;

  const { setRouteAccess } = useNavigation();
  const dialog = useDialog();

  const [files, setFiles] = useState<File[] | undefined>(localState?.files);
  const [sheetData, setSheetData] = useState<object[]>();
  const [sheetLength, setSheetLength] = useState<number>();
  const [sheetsConfigs, setSheetsConfigs] = useState<TSheetConfig[] | []>([]);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [origins, setOrigins] = useState<TCrmOrigins>();

  const [isLoadingData, startLoadData] = useLoading(true);

  const navigationButtons = useRef<HTMLDivElement>(null);
  const sheetsLoaded = useRef<TSheetLoaded[]>([]);
  const loadedSheetIndex = useRef<number>(0);
  const ableToProcessSheet = useRef<boolean>(false);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const currentSheetInfo = files && {
    name: files[loadedSheetIndex.current]?.name,
    size: files[loadedSheetIndex.current]?.size,
    configs: sheetsConfigs.find(
      (config) => config.sheet == files[loadedSheetIndex.current]?.name,
    )?.config,
    index: loadedSheetIndex.current,
    counter: loadedSheetIndex.current + 1,
  };

  const hasContactSelected = currentSheetInfo?.configs?.find((config) =>
    possibleContactValues.includes(config.value),
  )
    ? true
    : false;

  const hasJobSelected = currentSheetInfo?.configs?.find((config) =>
    possibleJobValues.includes(config.value),
  )
    ? true
    : false;

  const currentComboboxData = comboboxData.filter(
    (item) =>
      !sheetsConfigs
        .find((config) => config.sheet == currentSheetInfo?.name)
        ?.config?.some((hConfig) => item.value == hConfig.value),
  );

  function getOriginsDetails() {
    if (sheetCrmOriginId) {
      api
        .get<TCrmOrigin>('/crm/origens', {
          params: { id_origem: sheetCrmOriginId },
        })
        .then(({ data }) =>
          setOrigins((prev) => {
            if (prev) {
              return { ...prev, sheetOrigin: data };
            }
            return { sheetOrigin: data };
          }),
        );
    }

    if (appCrmOriginId) {
      api
        .get<TCrmOrigin>('/crm/origens', {
          params: { id_origem: appCrmOriginId },
        })
        .then(({ data }) =>
          setOrigins((prev) => {
            if (prev) {
              return { ...prev, appOrigin: data };
            }
            return { appOrigin: data };
          }),
        );
    }
  }

  function autoAssignComboboxesValues(fileName: string, tableData: object[]) {
    const oldSheetConfigs = sheetsConfigs.slice();

    const identifiedValues: TSheetConfig = {
      sheet: fileName,
      config: [],
    };

    const existentConfig = sheetsConfigs.find(
      (config) => config.sheet == fileName,
    );

    if (!existentConfig) {
      Object.keys(tableData[0]).forEach((header, index) => {
        const findedConfig = findConfigByValue(header);
        if (
          findedConfig &&
          !identifiedValues.config.find(
            (config) => config.value == findedConfig.value,
          )
        ) {
          const headerConfig = {
            idColumn: index,
            label: findedConfig.label,
            value: findedConfig.value,
          };
          identifiedValues.config.push(headerConfig);
        }
      });

      oldSheetConfigs.push(identifiedValues);

      setSheetsConfigs((old) => [...old, identifiedValues]);

      if (!ableToProcessSheet.current) {
        ableToProcessSheet.current = true;
      }
    }
  }

  function addComboboxValue(values: THeaderConfig) {
    const sheetsConfigs_copy = sheetsConfigs.slice();

    const findExistentSheet =
      sheetsConfigs_copy.findIndex(
        (config) => config.sheet == currentSheetInfo?.name,
      ) ?? -1;

    if (!currentSheetInfo?.configs) {
      sheetsConfigs_copy.push({ sheet: currentSheetInfo?.name, config: [] });
      const newConfigIndex = sheetsConfigs_copy.findIndex(
        (config) => config.sheet == currentSheetInfo?.name,
      );

      sheetsConfigs_copy[newConfigIndex].config.push(values);
    } else {
      const findExistentColumnIndex =
        currentSheetInfo?.configs?.findIndex(
          (item) => item.idColumn === values.idColumn,
        ) ?? -1;

      if (findExistentColumnIndex > -1) {
        sheetsConfigs_copy[findExistentSheet].config.splice(
          findExistentColumnIndex,
          1,
        );
      }

      if (!values.value) return setSheetsConfigs([...sheetsConfigs_copy]);

      if (findExistentSheet > -1) {
        sheetsConfigs_copy[findExistentSheet].config.push(values);
      }
    }
    setSheetsConfigs(sheetsConfigs_copy);
  }

  async function loadSheetFile(file: File) {
    if (file) {
      loadedSheetIndex.current =
        files?.findIndex(
          (aFile) => aFile.name == file.name && aFile.size == file.size,
        ) ?? -1;
      try {
        const spreadsheetLoaded = sheetsLoaded.current.find(
          (loaded) => loaded.name == file.name && loaded.size == file.size,
        );
        if (spreadsheetLoaded) {
          setSheetData(spreadsheetLoaded.data);
          setSheetLength(spreadsheetLoaded.length);
        } else {
          const tableValues = await sheetFileToJson(file, sheeetRows);
          const sheetLength = await getSheetLength(file);

          if (tableValues) {
            setSheetData(tableValues);

            if (sheetLength) {
              setSheetLength(sheetLength);
            } else {
              setSheetLength(0);
            }

            if (tableValues.length > 0) {
              autoAssignComboboxesValues(file.name, tableValues);
              if (
                !sheetsLoaded.current.find(
                  (loaded) =>
                    loaded.name == file.name && loaded.size == file.size,
                )
              ) {
                sheetsLoaded.current.push({
                  name: file.name,
                  size: file.size,
                  data: tableValues,
                  length: sheetLength ?? 0,
                });
              }
            } else {
              ableToProcessSheet.current = true;
              deleteSheet(file.name);
            }
          } else {
            myToast.warn('Não há planilha(s) pendente de configuração!');

            backPage();
          }
        }
      } catch (error) {
        myToast.error(
          'Ocurreu um erro ao carregar a(s) planilha(s), tente novamente!',
        );
        backPage();
      }
    } else {
      myToast.error('Não há planilha pendente de configuração!');
      backPage();
    }
  }

  function getHeaderValue(indexColumn: number) {
    const value =
      currentSheetInfo?.configs?.find((item) => item.idColumn === indexColumn)
        ?.label ?? comboboxData[0].label;
    return String(value);
  }

  function validateCurrentConfig() {
    if (
      currentSheetInfo &&
      currentSheetInfo.configs &&
      currentSheetInfo.configs.length > 0
    ) {
      if (!hasContactSelected) {
        myToast.warn(
          '*Selecione* ao menos uma coluna contendo *informações de contato* (celular, telefone ou email).',
        );
        return false;
      }
      if (!hasJobSelected) {
        myToast.warn(
          '*Selecione* ao menos uma coluna contendo *informações de profissão* (profissão ou área de atuação).',
        );

        return false;
      }

      return true;
    } else {
      myToast.warn(
        '*Selecione* ao menos *uma coluna*. Se necessário remova a planilha!',
      );
      return false;
    }
  }

  async function loadNextSheet() {
    const currentConfigIsValid = validateCurrentConfig();

    if (currentConfigIsValid) {
      if (files && loadedSheetIndex.current < files.length - 1) {
        await startLoadData(() =>
          loadSheetFile(files[loadedSheetIndex.current + 1]),
        );
        // setTimeout(() => {
        //   navigationButtons.current?.scrollIntoView();
        // }, 100);
      }
    }
  }

  async function loadPreviousSheet() {
    if (files && loadedSheetIndex.current > 0) {
      await startLoadData(() =>
        loadSheetFile(files[loadedSheetIndex.current - 1]),
      );
      // setTimeout(() => {
      //   navigationButtons.current?.scrollIntoView();
      // }, 100);
    }
  }

  function backPage(
    showMessage: boolean = true,
    timeOut: number | boolean = 2000,
  ) {
    showMessage && myToast.info('Voltando à tela de cadastro de planilha...');

    if (typeof timeOut == 'number') {
      timeoutRef.current = setTimeout(() => {
        navigate('/cadastrar-planilha');
      }, timeOut);
    } else {
      navigate('/cadastrar-planilha');
    }
  }

  function handleRemoveSheet() {
    dialog
      .open({
        type: 'error',
        title: (
          <p className="text-xl font-bold">
            Realmente deseja <span className="text-pormade-red">excluir</span> a
            planilha?
          </p>
        ),
        text: 'Não será possível recuperá-la',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Manter',
        cancelButtonText: 'Excluir',
      })
      .then((response) => {
        if (!response?.isConfirmed) {
          currentSheetInfo && deleteSheet(currentSheetInfo.name);
          myToast.info(`Planliha *"${currentSheetInfo?.name}"* excluída!`, {
            autoClose: 2500,
          });
        }
      });
  }

  function deleteSheet(sheetName: string) {
    if (files && files?.length > 1) {
      const oldFiles = files.slice();
      const fileIndex = oldFiles.findIndex((file) => file.name == sheetName);

      sheetsLoaded.current = sheetsLoaded.current.filter(
        (loaded) =>
          loaded.name != sheetName && loaded.size != currentSheetInfo?.size,
      );

      setSheetsConfigs((old) =>
        old.filter((config) => config.sheet != sheetName),
      );

      oldFiles.splice(fileIndex, 1);

      setFiles(oldFiles);
    } else {
      setFiles(undefined);
    }
  }

  function handleSendData() {
    dialog
      .open({
        type: 'question',
        title: 'Deseja processeguir?',
        text:
          files && files.length > 1
            ? 'Finalizar configuração das planilhas?'
            : 'Finalizar configuração da planilha?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      })
      .then((response) => {
        if (response?.isConfirmed) {
          sendData();
        }
      });
  }

  function sendData() {
    if (validateCurrentConfig()) {
      const newSheetsConfigs: TSheetsConfigs = {
        planilha_crm_origem_id: Number(sheetCrmOriginId),
        app_crm_origem_id: appCrmOriginId ? Number(appCrmOriginId) : null,
        config_planilhas: [],
      };

      sheetsConfigs.forEach((sheetConfig) => {
        let newSheetConfig: any = {
          planilha: sheetConfig.sheet,
          colunas_ids: getInitialHeadersIDs(),
        };

        sheetConfig.config.forEach((config) => {
          newSheetConfig.colunas_ids[config.value] = config.idColumn;
        });

        newSheetsConfigs.config_planilhas.push(newSheetConfig);
      });

      setRouteAccess('sheetProcessing', true);

      if (files) {
        processSheet({
          files: files,
          sheetConfigs: newSheetsConfigs,
        });

        navigate('/leads');
      }
    }
  }

  useEffect(() => {
    if (files) {
      if (ableToProcessSheet.current) {
        if (loadedSheetIndex.current >= files.length - 1) {
          startLoadData(() => loadSheetFile(files[files.length - 1]));
        } else {
          startLoadData(() => loadSheetFile(files[loadedSheetIndex.current]));
        }
      }
    } else {
      backPage(false, false);
    }
  }, [files]);

  useEffect(() => {
    getOriginsDetails();

    if (files && files.length > 0) {
      startLoadData(() => loadSheetFile(files[0]));
    } else {
      myToast.error('Não há planilha pendente de configuração!');
      backPage();
    }

    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
      setRouteAccess('configSheet', false);
    };
  }, []);

  return (
    <DefaultPage
      goBackTo="/cadastrar-planilha"
      pageDescription={{
        title: 'CONFIGURAR PLANILHA',
        description: (
          <>
            <p className="leading-7">
              Com base nos dados da planilha, <b>identifique</b> e{' '}
              <b>selecione</b> o tipo de <b>informação</b> presente em cada
              coluna.
            </p>
          </>
        ),
      }}
    >
      <Dialog
        onClose={() => setIsOpenDeleteDialog(false)}
        open={isOpenDeleteDialog}
        onOpenChange={setIsOpenDeleteDialog}
        className="shadow-lg shadow-pormade-red/50"
        content={
          <div className="mb-5 grid text-center">
            <AlertTriangle
              className="mb-4 place-self-center text-pormade-red"
              size={40}
            />
            <p className="text-xl font-bold">
              Realmente deseja <span className="text-pormade-red">excluir</span>{' '}
              a planilha?
            </p>
            <p className="mt-4 flex items-center justify-center gap-2 text-base text-dark-gray">
              Não será possível recuperá-la
            </p>
          </div>
        }
        footer={
          <div className="flex gap-5">
            <Button
              variant="destructive"
              onClick={() => {
                currentSheetInfo && deleteSheet(currentSheetInfo?.name);
                setIsOpenDeleteDialog(false);
                myToast.info(
                  `Planilha *"${currentSheetInfo?.name}"* excluída!`,
                );
              }}
            >
              Excluir
            </Button>
            <Button
              variant="outline"
              onClick={() => setIsOpenDeleteDialog(false)}
            >
              Manter
            </Button>
          </div>
        }
      />

      <div className="w-full max-w-7xl">
        <div className="mb-10 flex flex-wrap items-center gap-2 self-start text-xs sm:text-sm md:text-base">
          {origins?.sheetOrigin ? (
            <div className="flex items-center gap-2 rounded-md bg-pormade px-2 py-1 text-white">
              <Pin size={20} />
              <p className=" ">{origins.sheetOrigin.name}</p>
            </div>
          ) : (
            ''
          )}

          {origins?.appOrigin ? (
            <div className="flex items-center gap-2 rounded-md bg-pormade px-2 py-1 text-white">
              <Pin size={20} />
              <p className="">{origins.appOrigin.name}</p>
            </div>
          ) : (
            ''
          )}
        </div>

        <Title marginBottom className="text-center">
          <div>
            <p>Configurar Planilha</p>
          </div>
        </Title>
        <Subtitle className="text-center text-muted-foreground">
          <>
            <b>Selecione</b> o tipo de informação presente em cada coluna da
            planilha
          </>
        </Subtitle>

        {sheetData ? (
          sheetData.length > 0 && !isLoadingData ? (
            <div className="mt-2 w-full">
              <DataTable
                sort
                outline
                showTotal={false}
                pagination={false}
                sheetRows={sheeetRows}
                data={sheetData}
                onRemove={() => handleRemoveSheet()}
                title={
                  <p className="base-text self-start font-normal">
                    {currentSheetInfo?.name}{' '}
                    <span className="medium-text text-pormade/80">
                      - (Prévia)
                    </span>
                  </p>
                }
                headerElement={(index) => (
                  <Combobox
                    search={false}
                    data={currentComboboxData}
                    title={getHeaderValue(index)}
                    className={cn(
                      'transition-color mt-4 w-full duration-200',
                      getHeaderValue(index) !== comboboxData[0].label
                        ? 'bg-pormade text-white hover:bg-pormade-hover hover:text-white'
                        : 'hover:bg-muted-foreground/10',
                    )}
                    onChange={(item) =>
                      addComboboxValue({
                        idColumn: index,
                        value: String(item.value),
                        label: String(item.label),
                      })
                    }
                  />
                )}
              />
              <p className="text-end">Total: {sheetLength}</p>
            </div>
          ) : (
            ''
          )
        ) : (
          ''
        )}

        {!sheetData || sheetData.length <= 0 || isLoadingData ? (
          <div className="mt-5 w-full max-w-7xl items-center">
            <div className="mb-4 flex justify-between">
              <Skeleton className="h-10 w-[400px] rounded-md" />
              <Skeleton className="h-10 w-10 rounded-md" />
            </div>
            <div className="w-full space-y-2">
              <Skeleton className="h-[28rem] w-full">
                <div className="flex size-full items-center justify-center px-10">
                  {isLoadingData ? (
                    <Loading>
                      <p className="text-3xl">Carregando planilha</p>
                    </Loading>
                  ) : !sheetLength ? (
                    <p className="subtitle text-center text-gray">
                      A planilha <b>"{currentSheetInfo?.name}"</b> não contém
                      dados
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </Skeleton>
            </div>
            <div className="mt-5 flex justify-end gap-2">
              <Skeleton className="h-8 w-[5rem] rounded-md" />
            </div>
          </div>
        ) : (
          ''
        )}

        {files && currentSheetInfo?.counter ? (
          <p className="medium-text my-4 text-center">
            {currentSheetInfo?.counter !== undefined
              ? currentSheetInfo.counter
              : 0}
            /{files?.length}
          </p>
        ) : (
          ''
        )}

        {files && files.length > 0 && sheetData && sheetData.length > 0 ? (
          <div
            className="flex flex-wrap-reverse items-center justify-center gap-5"
            ref={navigationButtons}
          >
            {files.length > 1 ? (
              <Button
                variant={'outline'}
                disabled={loadedSheetIndex.current <= 0 || isLoadingData}
                onClick={() => {
                  loadPreviousSheet();
                }}
                title="Configurar planilha anterior"
              >
                Planilha anterior
              </Button>
            ) : (
              ''
            )}
            {loadedSheetIndex.current < files?.length - 1 ? (
              <Button
                disabled={isLoadingData}
                variant={'outline'}
                onClick={() => {
                  loadNextSheet();
                }}
                title="Configurar próxima planilha"
              >
                Próxima planilha
              </Button>
            ) : (
              <Button
                disabled={isLoadingData}
                onClick={handleSendData}
                title="Finalizar configuração das planilhas"
              >
                Finalizar configuração
              </Button>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    </DefaultPage>
  );
}
